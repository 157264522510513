import React from 'react';
import { isEmpty } from 'lodash';
// import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useAppointmentByRefno } from 'manila-zoo/hooks';
import AppointmentQR from './AppointmentQR';
import SetSchedule from './SetSchedule';
import AppointmentExpired from './AppointmentExpired';

function Appointment() {
  const history = useHistory();
  const { refno } = useParams();
  const [isLoading, data] = useAppointmentByRefno(refno);
  const handleClear = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refno');
    history.push('/');
  };
  if (isLoading || isEmpty(data)) {
    return (
      <div className="min-h-full bg-gradient-to-b from-gray-50 to-gray-200 relative pt-12">
        <div className="mx-auto shadow-primary-lg rounded-xl bg-white p-5 w-full max-w-sm text-center">
          <div>{isLoading ? 'Loading...' : 'Appointment not found!'}</div>
          {!isLoading ? (
            <div className="mt-4">
              <button
                className="btn primary sm"
                type="button"
                onClick={handleClear}
              >
                Go back to Homepage
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  // if (data?.status === 'DRAFT')
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: `/appointment/${refno}`,
  //         state: {
  //           payload: { _empty: 'foo' },
  //           refno,
  //         },
  //       }}
  //     />
  //   );
  if ((['EXPIRED']).indexOf(data?.status) > -1) {
    return <AppointmentExpired data={data} />;
  }
  if ((['PENDING', 'PAID']).indexOf(data?.status) > -1) {
    return <AppointmentQR data={data} />;
  }
  return <SetSchedule refno={refno} />;
}

Appointment.propTypes = {};

export default Appointment;
