import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

const IconDropdown = () => (
  <svg
    className="w-4 h-4"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 9l-7 7-7-7"
    />
  </svg>
);

function FormSelect({
  label,
  name,
  onChange,
  value,
  icon,
  placeholder,
  options,
  ...props
}) {
  const handleChange = ({ target }) => {
    onChange((state) => ({
      ...state,
      [name]: target.value,
    }));
  };
  const isActive = React.useMemo(() => {
    if (isEmpty(value) && !placeholder) return false;
    return true;
  }, [value, placeholder]);
  return (
    <div
      className={`input-wrapper ${isActive ? 'active' : ''} ${
        icon ? 'with-icon' : ''
      }`}
    >
      {!isEmpty(label) && (
        <div className="label">
          <div className="content">{label}</div>
        </div>
      )}
      <select onChange={handleChange} value={value} {...props}>
        {typeof placeholder !== 'boolean' && placeholder && (
          <option value="">{placeholder || '- Select -'}</option>
        )}
        {options.map((item) => (
          <option
            key={item.value}
            value={item.value}
            disabled={item.disabled}
            className={item.className || ''}
          >
            {item.label}
          </option>
        ))}
      </select>
      {icon && <div className="icon">{icon}</div>}
    </div>
  );
}

FormSelect.defaultProps = {
  label: '',
  placeholder: undefined,
  icon: <IconDropdown />,
};

FormSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  options: PropTypes.instanceOf(Array).isRequired,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.instanceOf(Object),
  ]),
};

export default React.memo(FormSelect);
