import React from 'react';
import moment from 'moment';
import history from 'setup/history';
import { req } from 'react-reqq-lite';
import { transformIncluded } from 'helper';
import { toastSuccess } from 'react-geek-toast';
import Guidelines, { Terms } from 'manila-zoo/components/Guidelines';
import { showAlert, showModal } from 'modules/components/Modal';
import {
  APPOINTMENT,
  BOOK_APPOINTMENT,
  CHECKOUT_APPOINTMENT,
  INIT_APPOINTMENT,
} from '../constants';

// const labelAdult = (count) =>
//   count > 1 ? `${count} Adults` : `${count} Adult`;
// const labelChild = (count) =>
//   count > 1 ? `${count} Children` : `${count} Child`;

export const getAppointment = (refno) => {
  req.get({
    key: APPOINTMENT,
    url: `/api/v1/pub/appointment/${refno}`,
    transform: (res) => {
      const raw = transformIncluded(res?.data, res?.included || []);

      const appointments = (raw?.included?.applications || []).map((item) => {
        const is_adult = +item?.attributes?.age >= 13;
        const is_senior = +item?.attributes?.age >= 60;
        return {
          id: item?.attributes?.appointment_refno || item.id,
          name: `${item?.attributes?.first_name} ${item?.attributes?.last_name}`,
          is_adult: is_adult && !is_senior,
          is_senior,
          is_student: item?.attributes?.is_student,
          is_pwd: item?.attributes?.is_pwd,
          is_manila_employee: item?.attributes?.is_manila_employee,
        }
      });

      const appointment_label = (() => {
        const counts = appointments.reduce((y, x) => {
          const a = { ...y };
          if (x.is_student) {
            a.STUDENT = (a.STUDENT ?? 0) + 1;
            return a;
          }
          if (x.is_manila_employee) {
            a.MANILA_EMPLOYEE = (a.MANILA_EMPLOYEE ?? 0) + 1;
            return a;
          }
          if (x.is_pwd) {
            a.PWD = (a.PWD ?? 0) + 1;
            return a;
          }
          if (x.is_senior) {
            a.SENIOR = (a.SENIOR ?? 0) + 1;
            return a;
          }
          if (x.is_adult) {
            a.ADULT = (a.ADULT ?? 0) + 1;
            return a;
          }
          a.CHILD = (a.CHILD ?? 0) + 1;
          return a;
        }, []);
        const tmp = [];
        if (counts.STUDENT > 0) tmp.push(`${counts.STUDENT} ${counts.STUDENT > 1 ? 'Students' : 'Student'}`);
        if (counts.MANILA_EMPLOYEE > 0) tmp.push(`${counts.MANILA_EMPLOYEE} ${counts.MANILA_EMPLOYEE > 1 ? 'Manila LGU Employees' : 'Manila LGU Employee'}`);
        if (counts.PWD > 0) tmp.push(`${counts.PWD} ${counts.PWD > 1 ? 'PWDs' : 'PWD'}`);
        if (counts.SENIOR > 0) tmp.push(`${counts.SENIOR} ${counts.SENIOR > 1 ? 'Seniors' : 'Senior'}`);
        if (counts.ADULT > 0) tmp.push(`${counts.ADULT} ${counts.ADULT > 1 ? 'Adults' : 'Adult'}`);
        if (counts.CHILD > 0) tmp.push(`${counts.CHILD} ${counts.CHILD > 1 ? 'Children' : 'Child'}`);
        return tmp;
      })();
      return {
        id: refno,
        status: raw?.attributes?.status,

        schedule_label: moment(
          new Date(raw?.included?.schedule?.attributes?.scheduled_date)
        ).format('dddd MMM DD, YYYY'),
        appointment_label,
        payment_url: raw?.attributes?.payment_url,
        total_amount: raw?.attributes?.amount,
        appointments,
      };
    },
  });
};

export const initAppointment = (payload, onSuccess, onError) => {
  sessionStorage.clear();
  req.post({
    key: INIT_APPOINTMENT,
    url: '/api/v1/pub/appointment/init',
    payload,
    onSuccess: (res) => {
      const token = res?.response?.token;
      const refno = res?.response?.application_batch_uuid;
      localStorage.setItem('token', token);
      localStorage.setItem('refno', refno);
      toastSuccess('Please set schedule!');
      onSuccess(refno);
    },
    onError,
  });
};

export const bookAppointment = (id, payload, onSuccess) => {
  req.post({
    key: BOOK_APPOINTMENT,
    url: `/api/v1/appointment/${id}/book`,
    payload,
    onSuccess,
  });
};

export const checkoutAppointment = (id, onSuccess) => {
  req.post({
    key: CHECKOUT_APPOINTMENT,
    url: `/api/v1/appointment/${id}/checkout`,
    onSuccess,
  });
};

export const getPrevTickets = () => {
  const data = localStorage.getItem('ticket_history') || '';
  return data.split('||').filter(x => !!x);
};

export const appendTicketHistory = (refno, prevTicks = getPrevTickets()) => {
  if (refno) prevTicks.unshift(refno);
  const uniqueItems = [...new Set(prevTicks)]
  localStorage.setItem('ticket_history', uniqueItems.filter(x => !!x).filter((x, i) => i < 10).join('||'));
};

export const clearAppointment = (id, noConfirm = false) => {
  const clear = () => {
    const prevTicks = getPrevTickets();
    localStorage.clear();
    sessionStorage.clear();
    appendTicketHistory(id, prevTicks);
    history.push('/');
  }
  if (noConfirm) {
    clear();
    return;
  }
  showAlert({
    title: 'Confirmation',
    content:
      'Are you sure you want to go back to homepage?',
    onYes: (closeAlert) => {
      closeAlert();
      clear();
    },
    onNoLabel: 'Cancel',
    onYesLabel: 'Go Back',
  });
}

export const showEmployeeEligibilityModal = () => {
  showModal({
    title: 'Eligibility',
    closeOnClickOutside: true,
    content: (onClose) => (
      <div className="px-6 pb-6">
        <div className="overflow-auto max-h-[400px]">
          <a
            href="/"
            className="absolute"
            style={{ opacity: 0 }}
            onClick={(y) => {
              y.preventDefault();
              onClose();
            }}
          >
            -
          </a>
          <div>I am one of the following:</div>
          <ul className="list-disc pl-6">
            <li>Employee of City government of Manila.</li>
            <li>Teachers under Manila Division of City Schools.</li>
            <li>Universidad de Manila.</li>
            <li>Pamantasan ng Lungsod ng Maynila.</li>
          </ul>
          <div className="mt-4 text-center">
            <button className="btn" type="button" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    ),
  });
}

export const showValidIDsModal = () => {
  showModal({
    title: 'Valid IDs',
    closeOnClickOutside: true,
    content: (onClose) => (
      <div className="px-6 pb-6">
        <div className="overflow-auto max-h-[400px]">
          <a
            href="/"
            className="absolute"
            style={{ opacity: 0 }}
            onClick={(y) => {
              y.preventDefault();
              onClose();
            }}
          >
            -
          </a>
          <ul className="list-disc pl-6">
            <li>New and/or old TIN ID</li>
            <li>Voter&apos;s ID</li>
            <li>Driver&apos;s License</li>
            <li>Barangay ID</li>
            <li>Unified Multipurpose ID</li>
            <li>National ID</li>
            <li>Senior ID</li>
            <li>PWD ID</li>
            <li>PhilHealth ID</li>
            <li>Postal ID</li>
            <li>Current School ID</li>
            <li>City Government of Manila Employees ID</li>
          </ul>
          <div className="mt-4 text-center">
            <button className="btn" type="button" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    ),
  });
}

export const showGuidelinesModal = (cb = false) => {
  showModal({
    title: 'Please read the Guidelines to continue',
    className: 'modal-lg',
    closeOnClickOutside: true,
    content: (onClose) => (
      <div className="px-6 pb-6">
        <div className="overflow-auto max-h-[560px]">
          <a
            href="/"
            className="absolute"
            style={{ opacity: 0 }}
            onClick={(y) => {
              y.preventDefault();
              onClose();
            }}
          >
            -
          </a>
          <Guidelines />
          <div className="mt-4 text-center">
            {typeof cb === 'function' ? (
              <button className="btn primary" type="button" onClick={() => {
                onClose();
                cb();
              }}>
                Continue
              </button>
            ) : (
              <button className="btn" type="button" onClick={onClose}>
                Close
              </button>
            )}
          </div>
        </div>
      </div>
    ),
  });
}

export const showTermsModal = (cb = false) => {
  showModal({
    // title: 'Terms and Conditions',
    className: 'modal-lg',
    closeOnClickOutside: true,
    content: (onClose) => (
      <div className="px-6 pb-6 pt-6">
        <div className="overflow-auto max-h-[400px]">
          <a
            href="/"
            className="absolute"
            style={{ opacity: 0 }}
            onClick={(y) => {
              y.preventDefault();
              onClose();
            }}
          >
            -
          </a>
          <Terms />
          <div className="mt-4 text-center space-x-4">
            <button className="btn primary" type="button" onClick={() => {
              onClose();
              cb();
            }}>
              I Agree
            </button>
            <button className="btn" type="button" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    ),
  });
}