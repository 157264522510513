import React from 'react';
import PropTypes from 'prop-types';
import logo from 'assets/img/logo.png';
import footerTrees from 'assets/img/footer-trees.svg';
// import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import {
  HiArrowRight,
  // HiDeviceMobile,
  HiLocationMarker,
  HiMail,
} from 'react-icons/hi';
import { FaFacebookSquare } from 'react-icons/fa';

function Footer({ getTicket, noTrees }) {
  const history = useHistory();
  const handleBook = () => {
    history.push('/book-appointment');
  };
  return (
    <div className="footer-wrapper">
      {!noTrees ? <img src={footerTrees} className="w-full" alt="" /> : null}
      <div className=" bg-gray-900">
        <div className="container mx-auto px-5">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 px-5 py-10">
            <div>
              <div className="text-yellow-500 mb-3">Quick Links</div>
              <div className="space-y-3 flex flex-col">
                <Link
                  to="/"
                  className="text-white text-sm font-semibold hover:text-opacity-100 hover:text-white text-opacity-75 transition"
                >
                  Home
                </Link>
                <Link
                  to="/about-us"
                  className="text-white text-sm font-semibold hover:text-opacity-100 hover:text-white text-opacity-75 transition"
                >
                  About Us
                </Link>
                <Link
                  to="/animals"
                  className="text-white text-sm font-semibold hover:text-opacity-100 hover:text-white text-opacity-75 transition"
                >
                  Animals
                </Link>
                <Link
                  to="/plants"
                  className="text-white text-sm font-semibold hover:text-opacity-100 hover:text-white text-opacity-75 transition"
                >
                  Plants
                </Link>
              </div>
            </div>
            <div>
              <div className="text-yellow-500 mb-3">Other Pages</div>
              <div className="space-y-3 flex flex-col">
                <Link
                  to="/terms"
                  className="text-white text-sm font-semibold hover:text-opacity-100 hover:text-white text-opacity-75 transition"
                >
                  Terms and Conditions
                </Link>
                <Link
                  to="/guidelines"
                  className="text-white text-sm font-semibold hover:text-opacity-100 hover:text-white text-opacity-75 transition"
                >
                  Guidelines
                </Link>
                <Link
                  to="/faq"
                  className="text-white text-sm font-semibold hover:text-opacity-100 hover:text-white text-opacity-75 transition"
                >
                  FAQ
                </Link>
                <Link
                  to="/about-us#contact-us"
                  className="text-white text-sm font-semibold hover:text-opacity-100 hover:text-white text-opacity-75 transition"
                >
                  Contact Us
                </Link>
                <Link
                  to="/privacy-policy"
                  className="text-white text-sm font-semibold hover:text-opacity-100 hover:text-white text-opacity-75 transition"
                >
                  Privacy Policy
                </Link>
              </div>
            </div>
            <div>
              <div className="text-yellow-500 mb-3">Contact Info</div>
              <div className="space-y-3 flex flex-col">
                <a
                  href="https://goo.gl/maps/Ly7am5fp9Q9RMDKp9"
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center space-x-2 text-white text-sm font-semibold hover:text-opacity-100 hover:text-white text-opacity-75 transition"
                >
                  <HiLocationMarker className="w-5 h-5 flex-shrink-0 text-yellow-500" />
                  <span>
                    M. Adriatico St, Malate, Manila, 1004 Metro Manila
                  </span>
                </a>
                {/* <a
                  href="/"
                  className="flex items-center space-x-2 text-white text-sm font-semibold hover:text-opacity-100 hover:text-white text-opacity-75 transition"
                >
                  <HiDeviceMobile className="w-5 h-5 flex-shrink-0 text-yellow-500" />
                  <span>+62 909 836 6911</span>
                </a> */}
                <a
                  href="mailto:manilazoo@manila.gov.ph"
                  className="flex items-center space-x-2 text-white text-sm font-semibold hover:text-opacity-100 hover:text-white text-opacity-75 transition"
                >
                  <HiMail className="w-5 h-5 flex-shrink-0 text-yellow-500" />
                  <span>manilazoo@manila.gov.ph</span>
                </a>
                <a
                  href="https://www.facebook.com/ManilaZooPH.OfficialPage"
                  className="flex items-center space-x-2 text-white text-sm font-semibold hover:text-opacity-100 hover:text-white text-opacity-75 transition"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="h-5 w-5 flex justify-center items-center">
                    <FaFacebookSquare className="w-4 h-4 flex-shrink-0 text-yellow-500" />
                  </div>
                  <span>Manila Zoo Official</span>
                </a>
              </div>
            </div>
            <div>
              {/* <div className="text-yellow-500 mb-3">Make a Reservation</div> */}
              <div className="space-y-3 flex flex-col">
                <p className="text-xs text-gray-300">
                  Rediscover the Manila Zoo and see the animals. The place has
                  greatly improved! And let&apos;s encourage friends and family
                  to go and say hello to the animals.{' '}
                </p>
                {getTicket ? (
                  <button
                    className="btn primary flex items-center justify-between space-x-4"
                    type="button"
                    onClick={handleBook}
                  >
                    <span>Get Ticket</span>
                    <HiArrowRight className="w-5 h-5 flex-shrink-0" />
                  </button>
                ) : null}
              </div>
            </div>
          </div>
          <div className="border-t border-gray-800 py-6">
            <div className="flex items-center justify-between space-x-2">
              <img src={logo} className="w-24" alt="" />
              <div className="text-xs text-gray-500">
                Copyright &copy; 2022. All rights reserved. | v1.0.1
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Footer.defaultProps = {
  getTicket: false,
  noTrees: false,
};

Footer.propTypes = {
  noTrees: PropTypes.bool,
  getTicket: PropTypes.bool,
};

export default Footer;
