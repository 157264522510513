/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import shortid from 'shortid';
import { req } from 'react-reqq-lite';
import FormSelect from './FormSelect';

const ENABLE_CACHE = true;

const getRegions = (params, set, loader = () => {}, onError) => {
  loader(true);
  req.get({
    key: 'ADDRESS_REGION',
    url: `/api/v1/regions`,
    params: { ...params, per_page: 1000 },
    onSuccess: (res) => {
      loader(false);
      set(
        (_.get(res, 'response.data') || []).map((item) => ({
          label: _.get(item, 'attributes.name'),
          value: _.get(item, 'attributes.code'),
        }))
      );
      onError(false);
    },
    onError: () => {
      loader(false);
      onError(true);
    },
    cache: ENABLE_CACHE,
  });
};
const getProvinces = (params, set, loader = () => {}) => {
  loader(true);
  req.get({
    key: 'ADDRESS_PROVINCE',
    url: `/api/v1/provinces`,
    params: { ...params, per_page: 1000 },
    onSuccess: (res) => {
      loader(false);
      set(
        (_.get(res, 'response.data') || []).map((item) => ({
          label: _.get(item, 'attributes.name'),
          value: _.get(item, 'attributes.code'),
        }))
      );
    },
    onError: () => {
      loader(false);
    },
    cache: ENABLE_CACHE,
  });
};
const getMunicipalities = (params, set, loader = () => {}) => {
  loader(true);
  req.get({
    key: 'ADDRESS_MUNICIPALITIES',
    url: `/api/v1/municipalities`,
    params: { ...params, per_page: 1000 },
    onSuccess: (res) => {
      loader(false);
      set(
        (_.get(res, 'response.data') || []).map((item) => ({
          label: _.get(item, 'attributes.name'),
          value: _.get(item, 'attributes.code'),
        }))
      );
    },
    onError: () => {
      loader(false);
    },
    cache: ENABLE_CACHE,
  });
};
const getBarangays = (params, set, loader = () => {}) => {
  loader(true);
  req.get({
    key: 'ADDRESS_BARANGAYS',
    url: `/api/v1/barangays`,
    params: { ...params, per_page: 1000 },
    onSuccess: (res) => {
      loader(false);
      set(
        (_.get(res, 'response.data') || []).map((item) => ({
          label: (_.get(item, 'attributes.name') || '').toUpperCase(),
          value: _.get(item, 'attributes.code'),
        }))
      );
    },
    onError: () => {
      loader(false);
    },
    cache: ENABLE_CACHE,
  });
};

export const getCodesFromName = (data) =>
  new Promise((resolve) => {
    if (_.isEmpty(data.province || '')) resolve({});
    getProvinces({ name: data.province, per_page: 1000 }, (provinces) => {
      const province =
        provinces.find((item) => item.label === data.province) || {};
      if (_.isEmpty(data.municipality || '') || !province.value) resolve({});
      getMunicipalities(
        { name: data.municipality, per_page: 1000 },
        (municipalities) => {
          const municipality =
            municipalities.find((item) => item.label === data.municipality) ||
            {};
          if (_.isEmpty(data.municipality || '') || !municipality.value)
            resolve({ province_code: province.value });
          getBarangays({ name: data.barangay, per_page: 1000 }, (barangays) => {
            const barangay =
              barangays.find((item) => item.label === data.barangay) || {};
            if (_.isEmpty(data.barangay || '') || !barangay.value)
              resolve({
                province_code: province.value,
                municipality_code: municipality.value,
              });
            resolve({
              province_code: province.value,
              municipality_code: municipality.value,
              barangay_code: barangay.value,
            });
          });
        }
      );
    });
  });

const focusElement = (id) => {
  try {
    const elem = document.getElementById(id);
    elem.focus();
  } catch (err) {
    // eslint-disable-line
  }
};

function FormAddress({ onChange, value, required, isReadOnly, noBarangay }) {
  const [_id] = React.useState(shortid.generate());
  const [form, setForm] = React.useState({
    region_code: value.region_code || '',
    province_code: value.province_code || '',
    municipality_code: value.municipality_code || '',
    barangay_code: value.barangay_code || '',
  });
  const [regions, setRegions] = React.useState([]);
  const [regionsLoading, setRegionsLoading] = React.useState(false);
  const [regionError, setRegionError] = React.useState(false);
  const [provinces, setProvinces] = React.useState([]);
  const [provincesLoading, setProvincesLoading] = React.useState(false);
  const [municipalities, setMunicipalities] = React.useState([]);
  const [municipalitiesLoading, setMunicipalitiesLoading] =
    React.useState(false);
  const [barangays, setBarangays] = React.useState([]);
  const [barangaysLoading, setBarangaysLoading] = React.useState(false);
  const handleChange = (nextId) => (val) => {
    focusElement(nextId);
    setForm(val);
  };
  React.useEffect(() => {
    getRegions({}, setRegions, setRegionsLoading, setRegionError);
  }, []);
  React.useEffect(() => {
    setProvinces([]);
    if (_.isEmpty(form.region_code)) return;
    getProvinces(
      {
        region_code: form.region_code,
      },
      setProvinces,
      setProvincesLoading
    );
  }, [form.region_code]);
  React.useEffect(() => {
    setMunicipalities([]);
    if (_.isEmpty(form.province_code)) return;
    getMunicipalities(
      { province_code: form.province_code },
      setMunicipalities,
      setMunicipalitiesLoading
    );
  }, [form.province_code]);
  React.useEffect(() => {
    setBarangays([]);
    if (_.isEmpty(form.municipality_code)) return;
    getBarangays(
      { municipality_code: form.municipality_code },
      setBarangays,
      setBarangaysLoading
    );
  }, [form.municipality_code]);
  React.useEffect(() => {
    onChange((state) => ({ ...state, ...form }));
  }, [form, onChange]);
  React.useEffect(() => {
    if (value.region_code === form.region_code) return;
    setForm((state) => ({ ...state, region_code: value.region_code }));
    // eslint-disable-next-line
  }, [value.region_code]);
  React.useEffect(() => {
    if (value.province_code === form.province) return;
    setForm((state) => ({ ...state, province_code: value.province_code }));
    // eslint-disable-next-line
  }, [value.province_code]);
  React.useEffect(() => {
    if (value.municipality_code === form.province) return;
    setForm((state) => ({
      ...state,
      municipality_code: value.municipality_code,
    }));
    // eslint-disable-next-line
  }, [value.municipality_code]);
  React.useEffect(() => {
    if (value.barangay_code === form.province) return;
    setForm((state) => ({ ...state, barangay_code: value.barangay_code }));
    // eslint-disable-next-line
  }, [value.barangay_code]);

  return (
    <>
      <div className="">
        <FormSelect
          readOnly={isReadOnly}
          required={required}
          // isLoading={regionsLoading}
          placeholder={
            regionsLoading
              ? 'Loading...'
              : `${regionError ? '- Unable to load -' : '- Select -'}`
          }
          name="region_code"
          label="Region"
          onChange={handleChange(`${_id}-province-input`)}
          value={form.region_code || ''}
          options={regions}
          id={`${_id}-region-input`}
          onClick={() => {
            if (regionError) {
              setRegionError(false);
              getRegions({}, setRegions, setRegionsLoading, setRegionError);
            }
          }}
        />
      </div>
      <div className="">
        <FormSelect
          readOnly={isReadOnly}
          required={required}
          // isLoading={provincesLoading}
          placeholder={provincesLoading ? 'Loading...' : '- Select -'}
          name="province_code"
          label="Province"
          onChange={handleChange(`${_id}-city-input`)}
          value={form.province_code || ''}
          options={provinces}
          id={`${_id}-province-input`}
        />
      </div>
      <div className="">
        <FormSelect
          readOnly={isReadOnly}
          required={required}
          // isLoading={municipalitiesLoading}
          placeholder={municipalitiesLoading ? 'Loading...' : '- Select -'}
          name="municipality_code"
          label="Municipality"
          onChange={handleChange(`${_id}-barangay-input`)}
          value={form.municipality_code || ''}
          options={municipalities}
          id={`${_id}-city-input`}
        />
      </div>
      {!noBarangay && (
        <div className="">
          <FormSelect
            readOnly={isReadOnly}
            required={required}
            // isLoading={barangaysLoading}
            placeholder={barangaysLoading ? 'Loading...' : '- Select -'}
            name="barangay_code"
            label="Barangay"
            onChange={handleChange(null)}
            value={form.barangay_code || ''}
            options={barangays}
            id={`${_id}-barangay-input`}
          />
        </div>
      )}
    </>
  );
}

FormAddress.propTypes = {
  isReadOnly: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  noBarangay: PropTypes.bool,
};

FormAddress.defaultProps = {
  isReadOnly: false,
  required: false,
  noBarangay: false,
};

export default React.memo(FormAddress);
