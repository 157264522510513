import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import {
  useListAppointmentSchedules,
  useListAppointmentSlotOptions,
} from 'appointment-module/hooks';
import FormSelect from 'modules/forms/FormSelect';
import Divider from 'modules/components/Divider';
import { MapIcon } from '@heroicons/react/outline';

const today = new Date();

const SHOW_DETAILS = false;

function FormAppointmentSchedule({ facilityId, onChange }) {
  const [date, setDate] = React.useState(null);
  const [form, setForm] = React.useState({ slot: '' });
  const [isLoading, available] = useListAppointmentSchedules(facilityId);
  const [slots] = useListAppointmentSlotOptions(date);
  React.useEffect(() => {
    if (slots.length === 1) {
      setForm((state) => ({ ...state, slot: slots?.[0]?.data?.id }));
    }
  }, [slots, setForm]);
  const selected = slots.find((x) => x.value === form.slot)?.data;
  const handleChangeDate = (v) => {
    setDate(v);
    setForm({ slot: '' });
  };
  React.useEffect(() => {
    const raw = slots.find((x) => x.value === form.slot)?.data;
    onChange(raw);
  }, [form.slot, slots, onChange]);
  if (available.length < 1) {
    return (
      <div className="p-12">
        {isLoading ? (
          <div className="text-center text-gray-700 font-bold">Loading...</div>
        ) : (
          <div className="text-center text-red-500 font-bold">
            {!facilityId
              ? 'Select Test Center'
              : 'There are no available schedule at the moment.'}
          </div>
        )}
      </div>
    );
  }
  return (
    <div className="space-y-2">
      <div className="">
        <DatePicker
          onChange={handleChangeDate}
          openToDate={today}
          selected={date}
          includeDates={available}
          inline
          disabledKeyboardNavigation
        />
      </div>
      <div className="inline-block">
        <FormSelect
          name="slot"
          onChange={setForm}
          value={form.slot}
          label="Time Slot"
          options={slots}
          placeholder={!date ? '- Select Date -' : '- Select Time Slot -'}
          required
        />
      </div>
      {selected && SHOW_DETAILS && (
        <div>
          <Divider label="Appointment Details" />
          <div className="p-3 rounded bg-white">
            <div className="text-sm space-y-2">
              <div className="flex justify-between space-x-3">
                <span className="label">Date</span>
                <span className="answer">{selected?.schedule_date}</span>
              </div>
              <div className="flex justify-between space-x-3">
                <span className="label">Time</span>
                <span className="answer">{selected?.schedule_time}</span>
              </div>
              <div className="flex justify-between space-x-3">
                <span className="label">Where</span>
                <span className="answer">
                  <a
                    href={`https://www.google.com/maps?q=${selected?.geoloc}`}
                    target="_blank"
                    className="flex flex-col leading-tight text-gray-900"
                    rel="noopener noreferrer"
                  >
                    <span>{selected.address}</span>
                    <span className="flex space-x items-center justify-end text-xs text-primary-500">
                      <span>See Map</span>
                      <MapIcon className="w-4 h-4 ml-1 flex-none" />
                    </span>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

FormAppointmentSchedule.propTypes = {
  facilityId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FormAppointmentSchedule;
