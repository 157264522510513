import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { req, useApiGet } from 'react-reqq-lite';
import { HiOutlineGlobeAlt } from 'react-icons/hi';
import { showModal } from './Modal';
import LANG from './lang';

const OPTIONS = [
  { value: 'EN', label: 'English' },
  { value: 'PH', label: 'Filipino' },
];

const defaultLang = 'EN'; // localStorage.getItem('lang');

const setLanguage = (newLang) => {
  localStorage.setItem('lang', newLang);
  req.set('APP/lang', newLang);
};

const promptChangeLanguage = () => {
  showModal({
    title: 'Select Language',
    content: (onClose) => (
      <div className="px-5 pb-5">
        <div className="grid grid-cols-1 gap-3">
          {OPTIONS.map((x) => (
            <button
              className="flex items-center justify-between rounded-lg shadow-sm bg-white text-gray-300 hover:border-primary-500 hover:text-primary-500 p-3 font-medium transition focus:outline-none border border-gray-100"
              key={x.value}
              type="button"
              onClick={() => {
                setLanguage(x.value);
                onClose();
              }}
            >
              <div className="text-sm text-gray-800 font-semibold">
                {x.label}
              </div>
              <div className="font-bold leading-none rounded border px-2 py-1">
                {x.value}
              </div>
            </button>
          ))}
        </div>
      </div>
    ),
  });
};

export const LanguageSwitcher = ({ iconClassName, ...props }) => {
  const lang = useApiGet('APP/lang', defaultLang);
  const handleClick = (e) => {
    e.preventDefault();
    promptChangeLanguage();
  };
  return (
    <a {...props} href="/" onClick={handleClick}>
      <HiOutlineGlobeAlt className={iconClassName} /> {lang}
    </a>
  );
};

LanguageSwitcher.defaultProps = {
  iconClassName: 'inline-block h-6 w-6',
};

LanguageSwitcher.propTypes = {
  iconClassName: PropTypes.string,
};

export const useLanguage = () => {
  const lang = useApiGet('APP/lang', defaultLang);
  const text = React.useCallback(
    (key) =>
      get(LANG, `${key}.${lang}`) || get(LANG, `${key}.EN`) || '- NOT FOUND -',
    [lang]
  );
  return [text, lang];
};

const useInitLanguage = () => {
  const lang = useApiGet('APP/lang', defaultLang);
  React.useEffect(() => {
    if (!lang) {
      promptChangeLanguage();
    }
  }, [lang]);
  return [lang, setLanguage];
};

function Language() {
  useInitLanguage();
  return null;
}

Language.propTypes = {};

export default Language;
