/* eslint-disable react/no-array-index-key */
import React from 'react'

const MZOO_SCHEDULE_LIST = [
  { label: "Mondays", time: "11:00 AM - 8:00 PM" }, 
  { label: "Tuesdays - Sundays", time: "9:00 AM - 8:00 PM" }, 
  { label: "Holidays", time: "9:00 AM - 8:00 PM" }, 
  { label: "Monday - Holidays", time: "11:00 AM - 8:00 PM" }, 
  { label: "Last Entry of Visitors", time: "6:00 PM" }, 
]

function ZooSchedule() {
  return (
    <div className="font-light">
      {/* <div>We are open daily from <b className="font-semibold">11:00 A.M. to 8:00 P.M.</b>.</div>
      <div><span className="italic text-sm">(Bukas araw-araw tuwing <b className="font-semibold">11:00 A.M. to 8:00 P.M.</b>.)</span></div> */}
      <h3>Operating Hours:</h3>
      <table className="table-auto w-full text-sm md:text-base">
        <tbody>
          {
            MZOO_SCHEDULE_LIST.map((item, i) => (
              <tr key={i}>
                <td className="border px-2 py-2">{item.label}</td>
                <td className="border px-2 py-2 text-center">{item.time}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
    // <div className="text-yellow-400">
    //   The Manila Zoological and Botanical Garden is <b className="font-semibold">temporarily closed</b> due to its continuous development. Thank you for your understanding
    // </div>
  )
}

export default ZooSchedule