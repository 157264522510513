import React from 'react';
// import PropTypes from 'prop-types';
import logo from 'assets/img/logo.png';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <div className="header-wrapper py-3 fixed z-50 top-0 left-0 right-0 backdrop-blur-sm bg-black/60">
      <div className="container mx-auto px-5">
        <div className="flex items-center space-x-2 justify-between">
          <Link to="/">
            <img src={logo} className="w-24" alt="" />
          </Link>
          <div className="main-menu sm:flex space-x-10 hidden">
            <Link
              to="/guidelines"
              className="cursor-pointer inline-block text-white uppercase text-sm font-semibold tracking-wider border-b-2 border-transparent hover:border-white hover:text-white transition"
            >
              Guidelines
            </Link>
            <Link
              to="/book-appointment"
              className="cursor-pointer inline-block text-white uppercase text-sm font-semibold tracking-wider border-b-2 border-transparent hover:border-white hover:text-white transition"
            >
              Tickets
            </Link>
            <Link
              to="/events"
              className="cursor-pointer inline-block text-white uppercase text-sm font-semibold tracking-wider border-b-2 border-transparent hover:border-white hover:text-white transition"
            >
              Events
            </Link>
            <Link
              to="/animals"
              className="cursor-pointer inline-block text-white uppercase text-sm font-semibold tracking-wider border-b-2 border-transparent hover:border-white hover:text-white transition"
            >
              Animals
            </Link>
            <Link
              to="/plants"
              className="cursor-pointer inline-block text-white uppercase text-sm font-semibold tracking-wider border-b-2 border-transparent hover:border-white hover:text-white transition"
            >
              Plants
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {};

export default Header;
