import React from 'react';
// import PropTypes from 'prop-types';
import Header from './components/Header';
import Animals from './components/Animals';
import Footer from './Footer';

function AnimalsPage() {
  React.useEffect(() => {
    window.scrollTo(0,0)
  }, []);
  return (
    <>
      <div className="container mx-auto">
        <Header />
      </div>
      <div className="pt-[120px]">
        <div className="container mx-auto" style={{ minHeight: 'calc(100vh - 120px - 350px - 160px)' }}>
          <div>
            <Animals />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

AnimalsPage.propTypes = {}

export default AnimalsPage
