import React from 'react';
import illustration404 from 'assets/img/illustration-404.svg';
import { Link } from 'react-router-dom';
import footerTrees from 'assets/img/footer-trees.svg';

function Page404() {
  return (
    <div className="bg-bottom relative flex flex-col h-full items-center justify-center p-6">
      <img src={illustration404} alt="Illustration 404" />
      <p className="text-xs font-semibold text-black text-center">
        We can&apos;t seem to find the page you are looking for
      </p>
      <div className="flex flex-row space-x-4 mt-4">
        <Link to="/" className="btn outline-primary">
          Go Back to Homepage
        </Link>
      </div>
      <div className="w-full fixed bottom-0 left-0">
        <img src={footerTrees} className="w-full h-44 object-contain object-bottom" alt="" />
      </div>
    </div>
  );
}

Page404.propTypes = {};

export default Page404;
