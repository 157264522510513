import React from 'react';
import cn from 'classnames';

function Stage() {
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 250);
  }, []);
  const toggle = (e) => {
    e.preventDefault();
    setShow(!show);
  };
  if (process.env.REACT_APP_IS_PRODUCTION === '1') return null;
  return (
    <>
      <div
        className={cn(
          'fixed top-0 left-0 w-full bg-red-600 text-sm pointer-events-none py-3 text-center z-50 transform transition duration-500 ease-out overflow-hidden border-b border-red-400',
          { 'translate-y-0': show, '-translate-y-full': !show }
        )}
      >
        <span className="text-red-200">You are on staging environment. </span>
        <a
          className="text-white pointer-events-auto hover:underline hover:text-white font-bold"
          href="https://healthpassph.com"
          onClick={toggle}
        >
          Got it!
        </a>
      </div>
      <div
        className={cn(
          'fixed -mt-1 left-0 px-1 pointer-events-none font-bold z-50 transform transition duration-500 ease-out overflow-hidden',
          { 'translate-y-0': !show, '-translate-y-full': show }
        )}
      >
        <a
          className="pointer-events-auto text-2xs text-white bg-red-600 shadow-xl inline-block px-2 h-6 pt-1 rounded-md hover:text-white"
          href="/"
          onClick={toggle}
        >
          <i className="fa fa-exclamation-triangle" />
        </a>
      </div>
    </>
  );
}

Stage.propTypes = {};

export default Stage;
