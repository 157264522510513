import { formatDate, parseTime, transformIncluded } from 'helper';

export const transformFacility = (raw) => {
  const other_contact_number = (() =>
    (raw?.attributes?.other_contact_number || '').split(','))();
  return {
    id: raw?.attributes?.uuid,
    address: raw?.attributes?.address,
    geoloc: raw?.attributes?.geoloc,
    logo: raw?.attributes?.logo,
    name: raw?.attributes?.name,
    other_contact_number,
  };
};

export const transformSchedule =
  (included = []) =>
  (raw) => {
    const data = transformIncluded(raw, included);
    return {
      id: data.id,
      address: data?.attributes?.address,
      geoloc: data?.attributes?.geoloc,
      label: data?.attributes?.description,
      group_id: data?.attributes?.scheduled_date, // GROUP BY SCHEDULE DATE
      schedule_label: data?.attributes?.label,
      schedule_date: formatDate(
        data?.attributes?.scheduled_date,
        'MMM DD, YYYY (ddd)'
      ),
      schedule_time: `${parseTime(
        data?.attributes?.time_start,
        'hh:mmA'
      )}~${parseTime(data?.attributes?.time_end, 'hh:mmA')}`,
      available_slots: +data?.attributes?.available_slots,
      schedule_type: +data?.attributes?.with_payment === 1 ? 'Regular Rates' : 'Free Admission',
    };
  };

export const transformAppointment = (raw, included) => {
  const data = transformIncluded(raw, included);
  return {
    id: data?.attributes?.uuid,
    status: data?.attributes?.status,
    created_at: formatDate(data?.attributes?.created_at, 'MM/DD/YYYY hh:mmA'),
    appointment: {
      refno: data?.included?.appointment?.attributes?.refno,
      status: data?.included?.appointment?.attributes?.status,
    },
    masterlist: {
      full_name: `${data?.included?.employeeMasterlist?.attributes?.first_name} ${data?.included?.employeeMasterlist?.attributes?.middle_name} ${data?.included?.employeeMasterlist?.attributes?.last_name}`,
      gender: data?.included?.employeeMasterlist?.attributes?.gender,
      birth_date: formatDate(
        data?.included?.employeeMasterlist?.attributes?.birth_date
      ),
      mobile_number: data?.included?.employeeMasterlist?.attributes?.contact_no,
      // first_name: data?.included?.employeeMasterlist?.attributes?.first_name,
      // middle_name: data?.included?.employeeMasterlist?.attributes?.middle_name,
      // last_name: data?.included?.employeeMasterlist?.attributes?.last_name,
    },
    facility: transformFacility(data?.included?.laboratory),
    schedule: transformSchedule(included)(data?.included?.schedule),
  };
};
