import React from 'react';
import { Link } from 'react-router-dom';
import FeesTable from './FeesTable';
// import PropTypes from 'prop-types';

export const Terms = () => {
  return (
    <div className="space-y-4">
      <h1 className="text-lg font-semibold text-center text-gray-900">
        TERMS AND CONDITIONS
      </h1>
      <ul className="list-disc pl-8 space-y-2">
        <li>Fees shall be settled ahead thru E-Payment channels.</li>
        <li>Shall observe implemented safety protocols for New Normal</li>
        <li>
          Manila Zoo will only honor fully-paid reservation only on the
          specified scheduled date.
        </li>
        <li>
          No cancellation, refund or change can be made after reservation has
          been paid, except with declared natural calamities in Manila such as
          typhoon, flood and earthquake.
        </li>
        <li>
          Visitors may be allowed to use the cancelled slot within 7 days from
          the date that is has been cancelled, failure to do so regardless of
          the cause thereof, shall be forfeited.
        </li>
        <li>
          Littering is prohibited and violators would be subjected to fines.
        </li>
        <li>
          For the safety and comfort of our visitors, please observe safe
          distance from the viewing areas.
        </li>
        <li>
          Teasing, annoying, or any form of behavior that may subject animals on
          exhibit to stress shall be dealt accordingly.
        </li>
        <li>
          Only authorized personnel are allowed to access restricted zones for
          the safety of the visitors.
        </li>
        <li>
          Unsupervised children not allowed to access and play inside the
          Playground Guardian must be present at all times to guide and assist
          them on the proper use of play apparatuses and to avoid accidents.
        </li>
        <li>No Smoking</li>
        <li>
          Management only allows dine-in on the designated area and any forms of
          refreshments are not allowed except personal tumbler.
        </li>
        <li>
          Sharp and pointed objects, loud music, intoxicating drinks and unruly
          behavior inside the premises will not be tolerated by the Management.
          It is the prerogative of the Zoo personnel to act accordingly.
        </li>
        <li>
          For concerns and assistance, you may reach us via email or approach
          the info Desk (Revenue Collection Unit).
        </li>
      </ul>
    </div>
  );
};

export const AboutUs = () => {
  return (
    <div className="space-y-4">
      <h1 className="font-title text-3xl text-green-500">
        Manila Zoological and Botanical Garden
      </h1>
      <h4 className="font-semibold text-gray-900">ABOUT US</h4>
      <p>
        The Manila Zoological and Botanical Garden is the only public zoo in the
        City of Manila it first opened on July 25, 1959 during the tenure of
        Manila Mayor Arsenio H. Lacson.
      </p>
      <p>
        The Zoo&apos;s main attraction is Ma&apos;ali (Vishwama&apos;ali) a
        female Asian Elephant gifted by the government of Sri Lanka in 1977 and
        Kois the White Tiger, a male white Siberian Tiger donated by Zoocobia in
        2021.
      </p>
      <p>
        Manila Zoo also provide forever home to more than 550 specimens of
        exotic wildlife representing 13 species of mammals, 38 species of avian,
        and 21 species of reptiles.
      </p>
      <p>
        All animals are securely monitored to their lovely habitats while being
        maintained by group of Professional Veterinarians.
      </p>
      <p>
        Manila Zoo also nurtures a botanical garden where more than 10.000
        plants are being grown and propagated.
      </p>

      <h4 className="font-semibold text-gray-900">CONTACT US</h4>
      <p>For inquiries, you may reach us through:</p>
      <ul className="list-disc pl-6">
        <li>
          Email:{' '}
          <a className="hover:underline" href="mailto:manilazoo@manila.gov.ph">
            manilazoo@manila.gov.ph
          </a>
        </li>
        <li>
          Social Media:{' '}
          <a
            className="hover:underline"
            href="https://www.facebook.com/ManilaZooPH.OfficialPage"
            target="_blank"
            rel="noreferrer"
          >
            Official Facebook page
          </a>
        </li>
      </ul>
    </div>
  );
};

export const PrivacyPolicy = () => {
  return (
    <div className="space-y-4">
      <h1 className="text-lg font-semibold text-center text-primary-500">
        PRIVACY NOTICE
      </h1>
      <h4 className="text-lg font-semibold text-center text-primary-500">
        MANILA RESIDENTS, AND OTHER STAKEHOLDERS
      </h4>
      <p>
        Welcome to{' '}
        <span className="font-semibold text-primary-500">
          the City Government of Manila
        </span>
      </p>
      <p>
        This Privacy Notice informs you of our policy regarding the data we
        collect and how we use, transfer/disclose, and store/retain/dispose or
        otherwise process your personal data.
      </p>
      <p>
        We respect your right to privacy and aim to comply with the requirements
        of all relevant privacy and data protection laws, particularly the Data
        Privacy Act of 2012 (DPA). As in the case of the National Privacy
        Commission (NPC), we also seek to strike a balance between your personal
        privacy and the free flow of information, especially when pursuing our
        legitimate interests and when necessary to carry out our
        responsibilities.
      </p>
      <p>
        In this NOTICE, the terms &ldquo;data&rdquo; and
        &ldquo;information&rdquo; are used interchangeably. When we speak of
        &ldquo;personal data,&rdquo; the term includes the concepts of personal
        information, sensitive personal information, and privileged information.
        The first two are typically used to identify you distinctively. For
        their exact definitions, you may refer to the DPA text or visit the
        office of the Designated Data Protection Officer of Manila. You should
        also note that while we give examples here to explain this Privacy
        Notice in simple and clear language, such examples do not form an
        exhaustive list of all the data that we process.
      </p>
      <h4 className="text-primary-500">
        Information We Collect, Acquire, or Generate
      </h4>
      <p>
        We collect, acquire, or generate your personal data in many forms. These
        may consist of written records, photographic and video images, digital
        material, and even biometric records. Examples include:
      </p>
      <ul className="list-decimal pl-8 space-y-2">
        <li>
          <div className="pb-2">
            Information you provide us during your transaction. When you
            transact with us, we collect, among others:
          </div>
          <div>
            <ul className="list-[lower-latin] pl-8 space-y-2">
              <li>
                Directory information, (i.e., Name, Address, Gender, Birthdate,
                Birthplace, Telephone numbers, Email Address, Citizenship,
                Education, Age, Contact information, and Other relevant
                information in connection with the transactions with the City
                Government of Manila);
              </li>
              <li>
                Data about your personal circumstances, such as your family
                background, history, marital status, Government Records, and
                other relevant circumstances and employment records; and
              </li>
              <li>
                Any or all information obtained through interviews and/or other
                forms of personal data collection
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className="pb-2">
            Information we process or generate after the collection. When
            applying to City Government of Manila, as an employee, we may also
            collect additional information about you, including
          </div>
          <ul className="list-decimal pl-8 space-y-2">
            <li>Name</li>
            <li>Address</li>
            <li>Date of Birth</li>
            <li>Place of Birth</li>
            <li>Sex</li>
            <li>Civil Status</li>
            <li>Height</li>
            <li>Weight</li>
            <li>Blood Type</li>
            <li>GSIS ID No</li>
            <li>PagIBIG No</li>
            <li>PhilHealth No</li>
            <li>SSS No.</li>
            <li>Tin No.</li>
            <li>Citizenship</li>
            <li>Residential Address</li>
            <li>Zipcode</li>
            <li>Telephone Number</li>
            <li>Mobile Number</li>
            <li>Email Address</li>
            <li>
              <div className="pb-2">Family Background</div>
              <ul className="list-none pl-0 space-y-2">
                <li>&#10003; Spouse&rsquo;s Name</li>
                <li>&#10003; Occupation</li>
                <li>&#10003; Employer/Business Name</li>
                <li>&#10003; Telephone Number</li>
                <li>&#10003; Father&rsquo;s Name</li>
                <li>&#10003; Mother&rsquo;s Name</li>
              </ul>
            </li>
            <li>
              <div className="pb-2">Educational Background</div>
              <ul className="list-none pl-0 space-y-2">
                <li>&#10003; Elementary</li>
                <li>&#10003; Secondary</li>
                <li>&#10003; Vocational/Trade Course</li>
                <li>&#10003; College</li>
                <li>&#10003; Graduate Studies</li>
              </ul>
            </li>
            <li>Civil Service Eligibility</li>
            <li>Work Experience</li>
            <li>
              Voluntary Work or Involvement in
              Civic/Non-Government/People/Voluntary Organization/s
            </li>
            <li>
              Learning and Development (L&amp;D) Interventions/Training Programs
              Attended
            </li>
            <li>Special Skills and Hobbies</li>
            <li>
              Consanguinity of affinity to the appointing or recommending chief
              bureau or office or to the person who has immediate supervision
              over you in the Bureau or Department where you will be appointed
            </li>
            <li>Administrative Offense / criminal charges before any court</li>
            <li>
              Crime violation of any law, decree, ordinance or regulation by any
              court or tribunal
            </li>
            <li>Separation from service</li>
            <li>Candidate in National or local election</li>
            <li>
              Acquired status of an immigrant or permanent resident of another
              country
            </li>
            <li>Indigenous/Person with disability/ Solo Parent</li>
            <li>Character References</li>
            <li>Signature</li>
            <p>We may obtain these additional personal data when you:</p>
            <ul className="list-none pl-0 space-y-2">
              <li>&#10003; Avail the City of Manila Health Services</li>
              <li>&#10003; Register for Covid-19 Vaccination</li>
              <li>&#10003; Transact with the City of Manila Civil Registry</li>
              <li>&#10003; Avail services of the Social Welfare</li>
              <li>
                &#10003; Register as Senior Citizen resident of the City and
                avail its services
              </li>
              <li>&#10003; Apply for employment in the City of Manila</li>
              <li>&#10003; Apply for Manila Resident ID</li>
              <li>
                &#10003; Utilize any of the City of Manila Electronic Data
                Processing System
              </li>
              <li>&#10003; Avail service of the City Library</li>
              <li>&#10003; Carryout transaction concerning Business Permit</li>
              <li>
                &#10003; Carryout transaction concerning Real Property Tax
              </li>
            </ul>
          </ul>
        </li>
        <li>
          Unsolicited Information. There may be instances when personal
          information is sent to of received by us even without our prior
          request. In such cases, we will determine if we can legitimately keep
          such information. If it is not related to any of our legitimate
          interests, we will immediately dispose of the information in a way
          that will safeguard your privacy. Otherwise, it will be treated in the
          same manner as information you provide us as confidential and secure.
        </li>
      </ul>
      <p>
        Suppose you supply us with personal data of other individuals (e.g.,
        heirs). In that case, we will request you to certify that you have
        obtained the consent of such individuals before providing us with their
        personal data via consent form from the City Government of Manila&apos;s
        Data Protection Officer (DPO).
      </p>
      <h4 className="text-primary-500">How we Use Your Information</h4>
      <p>
        To the extent permitted or required by law, we use your personal data to
        pursue our legitimate interests as a local government unit of the City
        of Manila, including a variety of administrative, research, historical,
        and statistical purposes. For example, we may use the information we
        collect for purposes such as but not limited to:
      </p>
      <ul className="list-decimal pl-8 space-y-2">
        <li>Job Application</li>
        <li>Payroll Processing</li>
        <li>Avail the City of Manila Health Services</li>
        <li>Register for Covid-19 Vaccination</li>
        <li>Transact with the City of Manila Civil Registry</li>
        <li>Avail services of the Social Welfare</li>
        <li>
          Register as Senior Citizen resident of the City and avail its services
        </li>
        <li>Apply for employment in the City of Manila</li>
        <li>Apply for Manila Resident ID</li>
        <li>
          Utilize any of the City of Manila Electronic Data Processing System
        </li>
        <li>Avail service of the City Library</li>
        <li>Carryout transaction concerning Business Permit</li>
        <li>Carryout Transaction concerning Real Property Tax</li>
        <li>Bids and Awards activities, or</li>
        <li>Any for any other legitimate transaction with the city</li>
      </ul>
      <p>
        We consider the processing of your personal data for these purposes to
        be necessary for the performance of our contractual obligations to you,
        for our compliance with a legal obligation, to protect your vitally
        important interests, including your life and health, for the performance
        of tasks we carry out in the public interest (e.g., public order, public
        safety, etc. ), or for the legitimate interests of the City Government
        of Manila or a third party, among others. We understand that the DPA
        imposes stricter rules for processing sensitive personal information and
        privileged information, and we are fully committed to abiding by those
        rules.
      </p>
      <p>
        If we require your consent for any specific use of your personal data,
        we will collect it at the appropriate time.
      </p>
      <p>
        Please note further that we will not subject your personal date to any
        automated decision-making process without your prior consent.
      </p>
      <h4 className="text-primary-500">
        How we Share, Disclose, or Transfer Your Information
      </h4>
      <p>
        To the extent permitted or required by law, we may also share, disclose,
        or transfer your personal data to other persons or organizations in
        order to uphold your interests and/or pursue our legitimate interests as
        an academic institution. For Example, we may share, disclose, or
        transfer your personal data for purposes such as:
      </p>
      <ul className="list-decimal pl-8 space-y-2">
        <li>Internal and External Audit activities</li>
        <li>Availment of the City of Manila programs</li>
        <ul className="list-none ml-[-2rem] space-y-2">
          <li>&#10003; Manila Department of Social Welfare (MDSW) programs</li>
          <li>&#10003; Office For Senior Citizen Affair (OSCA)</li>
          <li>
            &#10003; Youth Development and Welfare Bureau Program like Sports
            Clinic
          </li>
          <li>&#10003; City of Manila Health Services</li>
        </ul>
        <li>And other programs of the City of Manila</li>
      </ul>
      <h4 className="text-primary-500">
        How we Store, Retain, and Dispose of your Information
      </h4>
      <p>
        Your personal data is stored and transmitted securely in a variety of
        paper and electronic formats, including databases that are shared
        between the City Government of Manila&apos;s different units or offices.
        The only people able to access data covered by this policy are those
        covered under this policy and whose functions necessarily require them
        to access such data. Rest assured that our use of your personal data
        will not be excessive. This policy applies to:
      </p>
      <ul className="list-disc pl-8 space-y-2">
        <li>The department/units of the City Government of Manila</li>
        <li>All employees of the City Government of Manilla</li>
        <li>
          All citizen/entities being served in various service units of the City
          Government of Manila such as but not limited to:
        </li>
        <ul className="list-none ml-[-2rem] space-y-2">
          <li>&#10003; Health Services</li>
          <li>&#10003; Civil Registry</li>
          <li>&#10003; Public Employment Services</li>
          <li>&#10003; City Library</li>
          <li>&#10003; Manila Department of Social Welfare (MDSW) programs</li>
          <li>&#10003; Office For Senior Citizens Affair (OSCA)</li>
          <li>&#10003; Youth Development and Welfare Bureau</li>
        </ul>
        <li>
          All contractors, suppliers, bidders, and other people working on
          behalf of the city.
        </li>
      </ul>
      <h4 className="text-primary-500">General Staff Guidelines</h4>
      <p>
        The only people able to access data are those covered under this policy
        and whose functions necessarily require them to access such data.
      </p>
      <p>
        Unless otherwise provided by law or by appropriate policies, we will
        retain your relevant personal data indefinitely for historical and
        statistical purposes. A retention period is provided by law and/or City
        Government of Manila policies, all affected records will be securely
        disposed of after such period.
      </p>
      <h4 className="text-primary-500">
        Your Rights with Respect to Your Personal Data
      </h4>
      <ul className="list-disc pl-8 space-y-2">
        <li>Right to be informed,</li>
        <li>Right to access,</li>
        <li>Right to object,</li>
        <li>Right to erase or block,</li>
        <li>Right to rectify,</li>
        <li>Right to damages,</li>
        <li>Right to data portability and</li>
        <li>Right to file a complaint.</li>
      </ul>
      <p>
        We recognize these rights with respect to your personal data, as
        provided by the DPA. If you wish to exercise any of your rights, or
        should you have any concern or question regarding them, this Notice, or
        any matter involving data privacy, you may contact the designated DPO of
        the City Government of Manila at: The DPO Office: e-mail address:
        manila.dpo@gmail.com.
      </p>
      <h4 className="text-primary-500">Changes in the Privacy Notice</h4>
      <p>
        We may, from time to time, make changes to this Privacy Notice. On such
        occasions, we will let you know through our website/s and, when
        permissible, other means of communication. Any modification is effective
        immediately upon posting on the website.
      </p>
      <h4 className="text-primary-500">Other City of Manila Policies</h4>
      <p>
        Other policies of the City Government of Manila, which are not
        inconsistent with this Privacy Notice, will continue to apply. Suppose
        any provision of this Notice is found to be unenforceable or invalid by
        any court having competent jurisdiction. In that case, the invalidity of
        such provision will not affect the validity of the other provisions,
        which shall remain in full force and effect.
      </p>
      <h4 className="font-semibold text-primary-500">
        NPC Seal of Registration
      </h4>
      <p>
        <a
          className="hover:underline"
          href="https://ms-npc-compliance.s3.ap-southeast-1.amazonaws.com/Multisys_COR.pdf"
          target="_blank"
          rel="noreferrer"
        >
          View Certificate
        </a>
      </p>
      <p>
        <a
          className="hover:underline"
          href="https://ms-npc-compliance.s3.ap-southeast-1.amazonaws.com/Multisys_SOR.pdf"
          target="_blank"
          rel="noreferrer"
        >
          View Seal
        </a>
      </p>
    </div>
  );
};

function Guidelines() {
  return (
    <div className="space-y-6 text-gray-700 max-w-3xl">
      <div className="space-y-4">
        <h4 className="font-semibold text-gray-900">GUIDELINES</h4>
        <p>
          The Manila Zoo under Public Recreations Bureau (PRB) has created a set
          of rules and guidelines for the safety and welfare of both our guests
          and the animals.
        </p>
        <p>
          Please obey all rules, guidelines and posted signs throughout the Zoo
          premises.
        </p>
        <ul className="list-decimal pl-8 space-y-2">
          <li className="font-semibold text-gray-900">RESPECT THE ANIMALS</li>
          <li className="font-semibold text-gray-900">
            DO NOT FEED THE ANIMALS
          </li>
          <li className="font-semibold text-gray-900">NO SMOKING</li>
          <li className="font-semibold text-gray-900">NO LITTERING</li>
          <li className="font-semibold text-gray-900">
            NO OUTSIDE FOOD AND DRINKS ALLOWED
          </li>
          <li className="font-semibold text-gray-900">
            PETS ARE NOT ALLOWED INSIDE THE ZOO PREMISES
          </li>
          <li className="font-semibold text-gray-900">
            BALLOONS AND ALL KINDS OF TOYS ARE NOT ALLOWED INSIDE
          </li>
          <li className="font-semibold text-gray-900">
            CHILDREN MUST BE SUPERVISED AT ALL TIMES
          </li>
          <li className="font-semibold text-gray-900">
            DO NOT PLUCK THE FLOWERS
          </li>
          <li className="font-semibold text-gray-900">
            DO NOT STEP ON THE GRASS
          </li>
        </ul>

        <h1 className="text-lg font-semibold text-center">
          THANK YOU FOR VISITING MANILA ZOO
        </h1>
      </div>
    </div>
  );
}

Guidelines.propTypes = {};

export default Guidelines;

export const Faq = () => {
  return (
    <div className="space-y-6 text-gray-700">
      <h1 className="text-lg font-semibold text-gray-900">
        Frequently Asked Questions
      </h1>
      <ul className="list list-disc pl-8 space-y-4">
        <li>
          <div className="italic">Q: How much is the entrance fee?</div>
          <div>
            <FeesTable />
          </div>
        </li>
        <li>
          <div className="italic">
            Q: What are the Operating Hours of the Zoo?
          </div>
          <div className="font-bold">
            A: Manila Zoological and Botanical Garden will be open between
            9:00am to 6:00pm.
          </div>
        </li>
        <li>
          <div className="italic">Q: Is the Zoo open during Holidays?</div>
          <div className="font-bold">
            A: Yes. The zoo is open during holidays.
          </div>
        </li>
        <li>
          <div className="italic">Q: Can I purchase tickets on-site? </div>
          <div className="font-bold">
            A: We encourage visitors to book online.{' '}
            <Link to="/book-appointment">Click here</Link>.
          </div>
        </li>
        <li>
          <div className="italic">Q: Are food and drinks allowed inside?</div>
          <div className="font-bold">
            A: Food and bottled drinks are not allowed inside except personal
            tumbler. However, there is a food park inside the Zoo where you can
            eat while resting.
          </div>
        </li>
        <li>
          <div className="italic">
            Q: What animals will I be able to see at Manila Zoological and
            Botanical Garden?
          </div>
          <div className="font-bold">
            A: We have more than 500 animals altogether, from more than 70
            species.
          </div>
        </li>
        <li>
          <div className="italic">
            Q: What if I need to go out and come back in again?
          </div>
          <div className="font-bold">
            A: That&apos;s okay, as long as you get a hand stamped at the
            entrance.
          </div>
        </li>
        <li>
          <div className="italic">Q: Can I smoke/vape inside the zoo?</div>
          <div className="font-bold">
            A: No. We don&apos;t allow smoking/vaping inside the zoo.
          </div>
        </li>
        <li>
          <div className="italic">Q: Can I bring any pets inside the zoo?</div>
          <div className="font-bold">
            A: Sad to say that pets are not allowed inside the zoo for health
            and security reason.
          </div>
        </li>
        <li>
          <div className="italic">
            Q: Can I feed the animals? Is it possible to hold any animals?
          </div>
          <div className="font-bold">
            A: Feeding our Animals are harmful to them and so to you, we also
            have strict policy of “No touching and No holding”.
          </div>
        </li>
        <li>
          <div className="italic">Q: Can I park in the Zoo parking lot?</div>
          <div className="font-bold">
            A: Visitors needs to purchase ticket in order to park inside the
            Zoo&apos;s Parking Lot.
          </div>
        </li>
        <li>
          <div className="italic">Q: Is it safe to visit the Zoo?</div>
          <div className="font-bold">
            A: The newly renovate zoo is much enjoyable and safer to the
            visitors.
          </div>
        </li>
        <li>
          <div className="italic">
            Q: What is the Zoo doing to keep areas clean for guests?{' '}
          </div>
          <div className="font-bold">
            A: In an effort to provide you with a safe and enjoyable zoo
            experience, our PARKS Department have increased their cleaning
            routine.
          </div>
        </li>
      </ul>
    </div>
  );
};
