import React from 'react';
// import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Soon from 'modules/partial/containers/Soon';
import Page404 from 'modules/partial/containers/Page404';
import BookAppointment from './BookAppointment';
import GuidelinesPage from './GuidelinesPage';
import TermsPage from './TermsPage';
import AboutUsPage from './AboutUsPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import FaqPage from './FaqPage';
import Appointment from './appointment';
import Checkout from './appointment/Checkout';
import Landing from './Landing';
import AnimalsPage from './AnimalsPage';
import PlantsPage from './PlantsPage';

function ManilaZoo() {
  return (
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route path="/book-appointment" component={BookAppointment} />
      <Route path="/appointment/:refno/checkout" component={Checkout} />
      <Route path="/appointment/:refno" component={Appointment} />
      <Route path="/guidelines" component={GuidelinesPage} />
      <Route path="/membership" component={Soon} />
      <Route path="/events" component={Soon} />
      <Route path="/privacy" component={Soon} />
      <Route path="/terms" component={TermsPage} />
      <Route path="/faq" component={FaqPage} />
      <Route path="/about-us" component={AboutUsPage} />
      <Route path="/privacy-policy" component={PrivacyPolicyPage} />
      <Route path="/animals" component={AnimalsPage} />
      <Route path="/plants" component={PlantsPage} />
      <Route path="/contact-us" component={Soon} />
      <Route component={Page404} />
    </Switch>
  );
}

ManilaZoo.propTypes = {};

export default ManilaZoo;
