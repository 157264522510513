import React from 'react';
// import PropTypes from 'prop-types';
import Header from './components/Header';
import Plants from './components/Plants';
import Footer from './Footer';

function PlantsPage() {
  React.useEffect(() => {
    window.scrollTo(0,0)
  }, []);
  return (
    <>
      <div className="container mx-auto">
        <Header />
      </div>
      <div className="pt-[120px]">
        <div className="container mx-auto" style={{ minHeight: 'calc(100vh - 120px - 350px - 160px)' }}>
          <div className="text-white space-y-4 pb-16 text-center">
            <h1 className="font-title text-5xl text-green-500">
              Explore our Plants
            </h1>
          </div>
          <div>
            <Plants />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

PlantsPage.propTypes = {}

export default PlantsPage
