import { useApiGet, useApiLoading } from "react-reqq-lite";
import { INIT_APPOINTMENT, BOOK_APPOINTMENT, CHECKOUT_APPOINTMENT, APPOINTMENT } from "manila-zoo/constants";
import { initAppointment, bookAppointment, checkoutAppointment, getAppointment } from "manila-zoo/actions";
import { useEffect, useState } from "react";

export const useAppointmentByRefno = (refno) => {
  const isLoading = useApiLoading(APPOINTMENT, 'get');
  const data = useApiGet(APPOINTMENT, {});
  useEffect(() => {
    getAppointment(refno);
  }, [refno]);
  return [isLoading, data];
}

export const useInitAppointment = () => {
  const isLoading = useApiLoading(INIT_APPOINTMENT, 'post');
  return [isLoading, initAppointment];
};

export const useBookAppointment = () => {
  const isLoading = useApiLoading(BOOK_APPOINTMENT, 'post');
  return [isLoading, bookAppointment];
};

export const useCheckoutAppointment = () => {
  const isLoading = useApiLoading(CHECKOUT_APPOINTMENT, 'post');
  return [isLoading, checkoutAppointment];
}

export const useLastSession = () => {
  const [refno] = useState(localStorage.getItem('refno'));
  return [refno];
};
