import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import logo from 'assets/img/logo.png';
import footerTrees from 'assets/img/footer-trees.svg';

function Soon() {
  return (
    <div className="bg-white fixed h-full w-full top-0 left-0 flex">
      <div className="m-auto flex flex-col justify-center">
        <div className="mx-auto">
          <img className="h-32 object-cover" src={logo} alt="logo" />
        </div>
        <div className="text-center mt-4 font-semibold text-gray-900">
          <div className="text-3xl font-bold">Coming Soon!</div>
          <div className="mt-4">
            <Link className="underline text-xs" to="/">
              Go Back to Home Page
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full fixed bottom-0 left-0">
        <img src={footerTrees} className="w-full h-44 object-contain object-bottom" alt="" />
      </div>
    </div>
  );
}

Soon.propTypes = {};

export default Soon;
