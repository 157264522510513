import React from 'react';
// import PropTypes from 'prop-types';
import { HiInformationCircle } from 'react-icons/hi';
import { showGuidelinesModal } from './actions';

const IATF_GUIDELINESS = false;

function Alerts() {
  const handleOpenGuidelines = (e) => {
    e.preventDefault();
    showGuidelinesModal();
  };
  return (
    <div className="space-y-4">
      <div className="text-center font-light">
        For more information you may read our{' '}
        <a
          className="underline text-primary-500 font-semibold"
          href="/guidelines"
          onClick={handleOpenGuidelines}
        >
          guidelines
        </a>
        .
      </div>
      {IATF_GUIDELINESS ? <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <HiInformationCircle
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <div className="text-sm text-yellow-700">
              <p>
                As per IATF guidelines, children age{' '}
                <b>11 years old and below</b> are <b>not allowed</b> inside the
                Zoo.
                <br />
                <i>
                  Only those who will be vaccinated are allowed with one
                  guardian or companion.
                </i>
                <br />
                Thank you for your cooperation and understanding.
              </p>
            </div>
          </div>
        </div>
      </div> : null}
    </div>
  );
}

Alerts.propTypes = {};

export default Alerts;
