export const FACILITIES_LIST = 'APPOINTMENT/facilities_list';
export const FACILITIES_PAGER = 'APPOINTMENT/facilities_pager';

export const FACILITY = 'APPOINTMENT/facility';
export const FACILITY_ERROR = 'APPOINTMENT/facility_error';

export const FACILITY_SCHEDULE_DATES = 'APPOINTMENT/facility_schedule_dates';
export const FACILITY_SCHEDULE_BY_DATE =
  'APPOINTMENT/facility_schedule_by_date';
export const VACCINE_APPLICATION = 'APPOINTMENT/vaccine_application';
export const APPLY_VACCINE = 'APPOINTMENT/apply_vaccine';
