import React from 'react';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';
import logo from 'assets/img/logo.png';
import { HiDuplicate, HiShare } from 'react-icons/hi';
import { useHistory, useLocation } from 'react-router-dom';
import { toastError, toastSuccess } from 'react-geek-toast';
import { clearAppointment } from 'manila-zoo/actions';
// import TicketHistory from 'manila-zoo/components/TicketHistory';

const copyToClipboard = (textToCopy) => {
  try {
    if (!navigator.clipboard) {
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;

      // Avoid scrolling to bottom
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      document.execCommand('copy');
      toastSuccess('Copied to clipboard!');
      document.body.removeChild(textArea);
      return;
    }
    navigator.clipboard.writeText(textToCopy);
    toastSuccess('Copied to clipboard!');
  } catch (err) {
    toastError('Unable to copy!');
  }
};

const QRItem = ({ data }) => {
  const location = useLocation();
  const history = useHistory();

  const handleShare = () => {
    const url = `${window.location.href}#${data.id}`;
    if (!navigator.share) {
      toastSuccess('Copied to clipboard!');
      copyToClipboard(url);
      return;
    }
    navigator
      .share({
        title: 'Share Appointment',
        text: `${window.location.href}#${data.id}`,
        url,
      })
      .then(() => toastSuccess('Copied to clipboard!'));
  };
  const handleCopyToClipboard = () => {
    const url = `${window.location.href}#${data.id}`;
    copyToClipboard(url);
  };
  const handlePreview = () => {
    history.push(`${location.pathname}#${data.id}`);
  };
  return (
    <div className="py-3 flex">
      <div className="flex-shrink-0">
        <button type="button" onClick={handlePreview}>
          {data?.id ? (
            <div className="p-1 rounded bg-white" style={{
              colorScheme: 'light only',
            }}>
              <QRCode value={data?.id} size={96} />
            </div>
          ) : null}
        </button>
      </div>
      <div className="flex-1 pl-4">
        <div className="text-2xl">{data.name}</div>
        <div className="text-sm">Share</div>
        <div className="space-x-3">
          <button
            className="btn primary rounded-full sm"
            type="button"
            onClick={handleShare}
          >
            <HiShare className="h-6 w-6" />
          </button>
          <button
            className="btn primary rounded-full sm"
            type="button"
            onClick={handleCopyToClipboard}
          >
            <HiDuplicate className="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  );
};

QRItem.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

const OTC_PAYMENT = false;

const ScheduleData = ({ individual, data }) => (
  <div className="border-b pb-8">
    <div className="text-center text-2xl font-semibold">
      {data?.schedule_label}
    </div>
    {!individual ? (
      <div className="text-center text-gray-800 uppercase">{data?.appointment_label}</div>
    ) : null}
    <div>
      {(data?.status === 'PENDING' && data?.payment_url) ? (
        <div className="border rounded-xl bg-yellow-100 border-yellow-300 p-5 space-y-3">
          <div className="text-center">
            <div className="text-sm">Amount to Pay:</div>
            <div className="text-2xl font-bold font-mono">{data?.total_amount}</div>
          </div>
          {OTC_PAYMENT ? <>
            <div className="text-center text-sm">You may bring this QR and pay Over-the-counter</div>
            <div className="flex justify-center">
              <div className="p-2 rounded-lg bg-white">
                <QRCode value={data.id} size={256} />
              </div>
            </div>
            <div className="flex items-center">
              <div className="border-b flex-1" />
              <div className="text-center text-sm px-3">or</div>
              <div className="border-b flex-1" />
            </div>
          </> : null}
          <div>
            <button className="btn primary rounded-full w-full" type="button" onClick={() => {
              window.open(data?.payment_url, '_blank');
            }}>
              Pay Now
            </button>
          </div>
        </div>
      ) : null}
    </div>
  </div>
);

ScheduleData.defaultProps = {
  individual: false,
};

ScheduleData.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  individual: PropTypes.bool,
};

function AppointmentQR({ data }) {
  const history = useHistory();
  const location = useLocation();
  const handleClear = () => {
    const id = `${data.id}:${data?.schedule_label}:${data?.appointments?.[0]?.name}`;
    clearAppointment(id);
  };
  const hashData = React.useMemo(() => {
    const hash = (location.hash || '').substring(1);
    if (!hash) return null;
    return (data?.appointments || []).find((x) => x.id === hash) || null;
  }, [location.hash, data]);
  const handleBack = () => {
    history.push(location.pathname);
  };
  if (hashData) {
    const ageLabel = () => {
      if (hashData.is_student) return 'STUDENT';
      if (hashData.is_manila_employee) return 'MANILA LGU EMPLOYEE';
      if (hashData.is_pwd) return 'PWD';
      if (hashData.is_senior) return 'SENIOR';
      if (hashData.is_adult) return 'ADULT';
      return 'CHILD';
    }
    return (
      <div className="flex flex-col min-h-full bg-gradient-to-b from-white via-primary-50 to-primary-100 relative" >
        <div className="py-4 mx-auto">
          <img className="h-14" src={logo} alt="brand" />
        </div>
        <div className="shadow-lg rounded-xl bg-white w-full max-w-md mx-auto flex flex-col space-y-4 p-8">
          <div className="text-primary-500 font-black font-title text-xl text-center">
            Appointment Details
          </div>
          <ScheduleData data={data} individual />
          <div className="py-3 space-y-3 flex flex-col items-center">
            <div className="inline-flex justify-center p-3 bg-white rounded-lg" style={{
              colorScheme: 'light only',
            }}>
              <QRCode value={hashData?.id} size={256} />
            </div>
            <div>
              <div className="text-center font-bold text-3xl">
                {hashData?.name}
              </div>
              <div className="text-center text-xl">
                {ageLabel()}
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-8">
            <button
              className="text-sm font-semibold text-primary-500"
              type="button"
              onClick={handleBack}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-full bg-gradient-to-b from-white via-primary-50 to-primary-100 relative">
      <div className="py-4 mx-auto">
        <img className="h-14" src={logo} alt="brand" />
      </div>
      <div className="shadow-lg rounded-xl bg-white w-full max-w-md mx-auto flex flex-col space-y-4 p-8">
        <div className="text-primary-500 font-black font-title text-xl text-center">
          {data?.status === 'PAID' ? 'Ticket Details' : 'Payment Details'}
        </div>
        <ScheduleData data={data} />
        {data?.status === 'PAID' ? (
          <div className="divide-y divide-gray-50 mx-3">
            {(data?.appointments || []).map((item) => (
              <QRItem key={item.id} data={item} />
            ))}
          </div>
        ) : null}
        <div className="flex justify-center mt-8">
          <button
            className="text-sm font-semibold text-primary-500"
            type="button"
            onClick={handleClear}
          >
            Back to homepage
          </button>
        </div>
        {/* <div>
          <TicketHistory />
        </div> */}
      </div>
    </div>
  );
}

AppointmentQR.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default AppointmentQR;
