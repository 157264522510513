import React from 'react';
// import PropTypes from 'prop-types';
import Alerts from 'manila-zoo/Alerts';
import { Redirect, useHistory } from 'react-router-dom';
import FormInput from 'modules/forms/FormInput';
import FormSelect from 'modules/forms/FormSelect';
import FormAddress from 'modules/forms/FormAddress';
import ReCaptcha from 'modules/components/ReCaptcha';
import FormIsManila from 'modules/forms/FormIsManila';
import FormPhoneNumber from 'modules/forms/FormPhoneNumber';
import { each, pick } from 'lodash';
import { HiArrowRight, HiOutlineMail } from 'react-icons/hi';
import {
  showEmployeeEligibilityModal,
  showTermsModal,
} from 'manila-zoo/actions';
import { useInitAppointment, useLastSession } from '../hooks';

const NO_ADDRESS_INPUT = true;

const GENDER_OPTIONS = [
  { label: 'MALE', value: 'MALE' },
  { label: 'FEMALE', value: 'FEMALE' },
];

const toUpperCase = (obj, keys) => {
  const newObj = {};
  each(obj, (v, k) => {
    newObj[k] =
      keys.indexOf(k) > -1 && typeof v === 'string' ? v.toUpperCase() : v;
  });
  return newObj;
};

function RegisterForm() {
  const [resetCaptcha, setResetCaptcha] = React.useState(new Date().getTime());
  const [refno] = useLastSession();
  const history = useHistory();
  const [form, setForm] = React.useState({
    mobile_number: '',
    email: '',
    first_name: '',
    last_name: '',
    gender: '',
    age: '',

    region_code: '',
    province_code: '',
    municipality_code: '',
    barangay_code: '',
    // age: '25',
    // barangay_code: '012801001',
    // email: 'alpha@yopmail.com',
    // first_name: 'Alpha',
    // gender: 'MALE',
    // last_name: 'Dev',
    // mobile_number: '639277744082',
    // municipality_code: '012801000',
    // province_code: '012800000',
    // region_code: '010000000',

    is_student: false,
    is_pwd: false,
    is_manila_employee: false,
  });
  const [isLoading, submit] = useInitAppointment();
  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = toUpperCase(form, ['first_name', 'last_name']);
    submit(
      payload,
      (newRefno) => {
        history.push({
          pathname: `/appointment/${newRefno}`,
          state: {
            main: payload,
          },
        });
      },
      () => {
        setResetCaptcha(new Date().getTime());
      }
    );
  };
  const handleCancel = () => {
    history.push('/');
  };

  const handleViewEligibility = (e) => {
    e.preventDefault();
    showEmployeeEligibilityModal();
  };

  const handleViewTerms = (e) => {
    e.preventDefault();
    showTermsModal(() => {
      setForm({ ...form, tos: true });
    });
  };

  // const emailValidation = () => {
  //   if (!//.test(form.email)){
  //     return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
  //   }
  //   return true
  // }

  const handleChangeAge = (v) => {
    const { age } = v({});
    const other =
      +age > 59
        ? {
            is_student: false,
            is_pwd: false,
            is_manila_employee: false,
          }
        : {};
    setForm({
      ...form,
      ...other,
      age,
    });
  };

  const handleToggle =
    (key) =>
    ({ target }) => {
      setForm({
        ...form,
        is_student: false,
        is_pwd: false,
        is_manila_employee: false,
        [key]: target.checked,
      });
    };

  // const handleChangeValue = key => (value) => {
  //   setForm({ ...form, [key]: value });
  // };
  const isSenior = +form?.age > 59;

  if (refno) {
    return <Redirect to={`/appointment/${refno}`} />;
  }
  return (
    <form
      className=" flex flex-col sm:grid sm:grid-cols-2  gap-3"
      onSubmit={handleSubmit}
    >
      <div>
        <FormInput
          autoFocus
          name="first_name"
          onChange={setForm}
          icon={false}
          value={form.first_name}
          label="First Name"
          className="uppercase"
        />
      </div>
      <div>
        <FormInput
          name="last_name"
          onChange={setForm}
          icon={false}
          value={form.last_name}
          label="Last Name"
          className="uppercase"
        />
      </div>
      <div>
        <FormPhoneNumber
          name="mobile_number"
          onChange={setForm}
          value={form.mobile_number}
          label="Mobile number"
        />
      </div>
      <div>
        <FormInput
          name="email"
          onChange={setForm}
          value={form.email || ''}
          icon={<HiOutlineMail />}
          validation={() => {
            const regex = new RegExp(
              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
            );
            return regex.test(form.email);
          }}
          label="Email Address"
          type="email"
        />
      </div>
      <div>
        <FormSelect
          name="gender"
          onChange={setForm}
          value={form.gender}
          label="Gender"
          options={GENDER_OPTIONS}
          placeholder="- Select -"
        />
      </div>
      <div>
        <FormInput
          name="age"
          onChange={handleChangeAge}
          value={form.age}
          icon={false}
          label="Age"
          type="number"
        />
      </div>
      {NO_ADDRESS_INPUT ? (
        <div className="sm:col-span-2">
          <FormIsManila onChange={setForm} value={form} />
        </div>
      ) : (
        <FormAddress
          onChange={setForm}
          value={pick(form, [
            'region_code',
            'province_code',
            'municipality_code',
            'barangay_code',
          ])}
          // required
        />
      )}

      <div className="sm:col-span-2">
        <div className="text-sm italic">Please check if apply:</div>
        <div className="flex flex-col space-y-1">
          <label>
            <input
              disabled={isSenior}
              className="form-checkbox disabled:bg-gray-50 h-5 w-5 rounded border"
              type="checkbox"
              onChange={handleToggle('is_student')}
              checked={form.is_student}
            />{' '}
            I am Student
          </label>
          <label>
            <input
              disabled={isSenior}
              className="form-checkbox disabled:bg-gray-50 h-5 w-5 rounded border"
              type="checkbox"
              onChange={handleToggle('is_pwd')}
              checked={form.is_pwd}
            />{' '}
            I am PWD (Persons with disabilities)
          </label>
          <label>
            <input
              disabled={isSenior}
              className="form-checkbox disabled:bg-gray-50 h-5 w-5 rounded border"
              type="checkbox"
              onChange={handleToggle('is_manila_employee')}
              checked={form.is_manila_employee}
            />{' '}
            I am Manila LGU Employee/Teacher of Manila.{' '}
            <a href="/" onClick={handleViewEligibility} className="underline">
              See eligibility.
            </a>
          </label>
          <label>
            <input
              disabled
              className="form-checkbox disabled:bg-gray-50 h-5 w-5 rounded border"
              type="checkbox"
              onChange={() => {}}
              checked={isSenior}
            />{' '}
            I am Senior Citizen (Must be 60 years old up)
          </label>
        </div>
      </div>

      <hr />

      <div className="sm:col-span-2">
        <div className="text-sm italic">Required:</div>
        <label>
          <input
            className="form-checkbox h-5 w-5 rounded border"
            type="checkbox"
            onChange={({ target }) => setForm({ ...form, tos: target.checked })}
            checked={!!form?.tos}
          />{' '}
          I agree with the{' '}
          <a href="/" onClick={handleViewTerms} className="underline">
            Terms and Conditions.
          </a>
        </label>
      </div>

      <div className="sm:col-span-2 flex justify-center">
        <ReCaptcha
          key={resetCaptcha}
          name="captcha"
          align="center"
          onVerify={setForm}
        />
      </div>
      <div className="sm:col-span-2 flex justify-center">
        <Alerts />
      </div>
      <div className="flex justify-end space-x-3 col-span-2">
        <button className="btn link" type="button" onClick={handleCancel}>
          Cancel
        </button>
        <button
          className="btn primary flex items-center space-x-4"
          type="submit"
          disabled={isLoading || !form.captcha || !form?.tos}
        >
          <span>Set Appointment</span>
          <HiArrowRight className="w-5 h-5 flex-shrink-0" />
        </button>
      </div>
    </form>
  );
}

RegisterForm.propTypes = {};

export default RegisterForm;
