import React from 'react';
import PropTypes from 'prop-types';
import FormSelect from './FormSelect';

function FormIsManila({ onChange, value }) {
  const val = `${value?.region_code}:${value?.province_code}:${value?.municipality_code}:${value?.barangay_code}`;
  const handleChange = (v) => {
    const { is_manila } = v({});
    const [region_code, province_code, municipality_code, barangay_code] =
      is_manila.split(':');
    onChange((state) => ({
      ...state,
      region_code,
      province_code,
      municipality_code,
      barangay_code,
    }));
  };
  return (
    <div>
      <FormSelect
        name="is_manila"
        label="Residency"
        placeholder="- Select -"
        options={[
          {
            label: 'Resident of Manila',
            value: '130000000:133900000:133900000:133910036',
          },
          {
            label: 'Non-Resident (Outside of Manila)',
            value: '010000000:012800000:012801000:012801001',
          },
        ]}
        onChange={handleChange}
        value={val}
      />
    </div>
  );
}

FormIsManila.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
};

export default FormIsManila;
