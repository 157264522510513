import moment from 'moment';
import { useState, useEffect, useCallback } from 'react';
import { debounce, isEmpty, forOwn, get, omitBy, each } from 'lodash';

export const transformIncluded = (x, included) => {
  if (!included || isEmpty(included)) return x;
  const rowIncluded = {};
  forOwn(x.relationships, (v, k) => {
    rowIncluded[k] = Array.isArray(v.data)
      ? v.data.map(
          (z) =>
            included.find(
              (y) => y.type === get(z, 'type') && y.id === get(z, 'id')
            ) || {}
        )
      : included.find(
          (y) => y.type === get(v, 'data.type') && y.id === get(v, 'data.id')
        ) || {};
  });
  const { links, relationships, type, ...rest } = x;
  return { ...rest, included: rowIncluded };
};

export const parseNumber = (str, default_value = false) => {
  const v = parseFloat(`${str}`.replace(/,/g, ''));
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(v)) return typeof default_value !== 'boolean' ? default_value : str;
  return v;
};

export const formatNumber = (v, decimal = 2) => {
  try {
    const n = parseNumber(v);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(n)) return v;
    return n.toLocaleString(undefined, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  } catch (err) {
    return v;
  }
};

export const formatDate = (date, format = 'MM/DD/YYYY', defaultValue = '-') => {
  if (!date) return defaultValue;
  const d = new Date(date);
  if (d.toString() === 'Invalid Date') return defaultValue;
  return moment(d).format(format);
};

export const storage = {
  get: (key, defaultValue = false) => {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (err) {
      return defaultValue;
    }
  },
  set: (key, value) => {
    const newValue = JSON.stringify(value);
    sessionStorage.setItem(key, newValue);
  },
  remove: (key) => {
    sessionStorage.removeItem(key);
  },
};

export const usePersistState = (key, state, config = {}) => {
  const [value, setValue] = useState(storage.get(key) || state);
  // eslint-disable-next-line
  const updateState = useCallback(
    debounce((newState) => {
      storage.set(key, newState);
    }, 300),
    []
  );
  useEffect(() => {
    updateState(value);
  }, [value]); // eslint-disable-line
  useEffect(() => {
    return () => {
      if (!config.keepOnUnmount) {
        storage.remove(key);
      }
    };
  }, []); // eslint-disable-line
  return [value, setValue];
};

export const removePersistState = (key) => {
  storage.remove(key);
};

export const mask = (str) => {
  const reg = /.{8}/;
  return str.replace(reg, (m) => '*'.repeat(m.length));
};

export const removeEmpty = (obj) => omitBy(obj, (x) => isEmpty(`${x}`));

export const parseTime = (t, format = 'hA') => {
  return moment(t, 'HH:mm:ss').format(format);
};

const MAP_UNIT = {
  K: 'km',
  M: 'mi',
  N: 'NM',
};

export const getDistance = (latLng1, latLng2, unit = 'K', formatted = true) => {
  const latLngA = (latLng1 || '').split(',').map((x) => (x || '').trim());
  const latLngB = (latLng2 || '').split(',').map((x) => (x || '').trim());
  const lat1 = get(latLngA, '0') || 'undefined';
  const lon1 = get(latLngA, '1') || 'undefined';
  const lat2 = get(latLngB, '0') || 'undefined';
  const lon2 = get(latLngB, '1') || 'undefined';
  if (
    Number.isNaN(Number(lat1)) ||
    Number.isNaN(Number(lon1)) ||
    Number.isNaN(Number(lat2)) ||
    Number.isNaN(Number(lon2))
  )
    return null;
  if (lat1 === lat2 && lon1 === lon2) {
    if (!formatted) return 0;
    return `${formatNumber(0)} ${MAP_UNIT[unit]}`;
  }

  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === 'K') {
    dist *= 1.609344;
  }
  if (unit === 'N') {
    dist *= 0.8684;
  }
  if (!formatted) return dist;
  return `${formatNumber(dist)} ${MAP_UNIT[unit]}`;
};

export const toGeoArr = (str, default_value = false) => {
  const arr = (str || '').split(',').map((l) => +l);
  if (Number.isNaN(Number(arr[0])) || Number.isNaN(Number(arr[1])))
    return default_value;
  return arr;
};

export const isValidLatLng = (latLng) => {
  try {
    if (
      !Number.isNaN(Number(latLng[0])) &&
      !Number.isNaN(Number(latLng[1])) &&
      latLng[0] >= -85.05112878 &&
      latLng[0] <= 85.05112878 &&
      latLng[1] >= -180 &&
      latLng[1] <= 180
    ) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const toGeoStr = (arr) => arr.join(',');

export const toOrdinalSuffix = (i) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};

export const openPgiUrl = (url, onCloseCallback) => {
  const title = `Payment`;
  const h = 760;
  const w = 775;
  // Fixes dual-screen position                         Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  // eslint-disable-next-line
  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : // eslint-disable-next-line
      screen.width;
  // eslint-disable-next-line
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : // eslint-disable-next-line
      screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `scrollbars=yes, width=${w / systemZoom}, height=${
      h / systemZoom
    }, top=${top}, left=${left}`
  );

  const timer = setInterval(() => {
    if (!newWindow || newWindow.closed) {
      onCloseCallback();
      clearInterval(timer);
    }
  }, 500);

  // Puts focus on the newWindow
  if (window.focus && newWindow) newWindow.focus();
};

export const isValidHttpUrl = (string) => {
  try {
    const url = new URL(string);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (_) {
    return false;
  }
};

/* eslint-disable */
export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
};
/* eslint-enable */

export const toUpperCase = (obj, keys) => {
  const newObj = {};
  each(obj, (v, k) => {
    if (keys.indexOf(k) > -1 && typeof v === 'string') {
      newObj[k] = v.toUpperCase();
      return;
    }
    newObj[k] = v;
  });
  return newObj;
};
