import React from 'react'
// import PropTypes from 'prop-types'
import logo from 'assets/img/logo.png';
import { clearAppointment } from 'manila-zoo/actions';

function AppointmentExpired() {
  const handleClear = () => {
    clearAppointment();
  }
  return (
    <div className="flex flex-col min-h-full bg-gradient-to-b from-white via-primary-50 to-primary-100 relative">
      <div className="py-4 mx-auto">
        <img className="h-14" src={logo} alt="brand" />
      </div>
      <div className="shadow-lg rounded-xl bg-white w-full max-w-md mx-auto flex flex-col space-y-4 p-8">
        <div className="text-red-500 font-black text-xl text-center">
          Transaction has expired.
        </div>
        <div className="flex justify-center mt-8">
          <button
            className="text-sm font-semibold text-primary-500"
            type="button"
            onClick={handleClear}
          >
            Back to homepage
          </button>
        </div>
      </div>
    </div>
  )
}

AppointmentExpired.propTypes = {
  // data: PropTypes.instanceOf(Object).isRequired,
}

export default AppointmentExpired
