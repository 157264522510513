import { toastSuccess } from 'react-geek-toast';
import { req } from 'react-reqq-lite';
import {
  APPLY_VACCINE,
  FACILITIES_LIST,
  FACILITIES_PAGER,
  FACILITY,
  FACILITY_ERROR,
  FACILITY_SCHEDULE_BY_DATE,
  FACILITY_SCHEDULE_DATES,
  VACCINE_APPLICATION,
} from './constants';
import {
  transformAppointment,
  transformFacility,
  transformSchedule,
} from './transformers';

export const listFacilities = (params) => {
  req.get({
    key: FACILITIES_LIST,
    url: '/api/v1/me/search-facilities',
    params,
    transform: (res) => {
      const data = (res.data || []).map(transformFacility);
      req.set(FACILITIES_PAGER, res?.meta?.pagination || {});
      return data;
    },
  });
};

export const getFacilityById = (id) => {
  req.set(FACILITY_ERROR, null);
  req.get({
    key: FACILITY,
    url: `/api/v1/pub/facilities/${id}`,
    transform: (res) => transformFacility(res?.data),
    onError: (res) => {
      req.set(FACILITY_ERROR, res.response);
    },
  });
};

export const getFacilitySchedules = (id, params) => {
  req.get({
    key: FACILITY_SCHEDULE_DATES,
    url: `/api/v1/pub/facilities/${id}/schedules`,
    params,
    transform: (res) => {
      const list = (res?.data || []).map(transformSchedule(res?.included));
      const grouped = {};
      list.forEach((item) => {
        const temp = grouped[item.group_id] || [];
        temp.push(item);
        grouped[item.group_id] = temp;
      });
      req.set(FACILITY_SCHEDULE_BY_DATE, grouped);
      return Object.keys(grouped);
    },
  });
};

export const applyVaccine = (payload, onSuccess) => {
  req.post({
    key: APPLY_VACCINE,
    url: '/api/v1/pub/appointments',
    payload,
    onSuccess: (res) => {
      toastSuccess(res?.response?.message || 'Application Submitted!');
      onSuccess(res?.response?.uuid || 'not-found');
    },
  });
};

export const getVaccine = (uuid) => {
  req.get({
    key: VACCINE_APPLICATION,
    url: `/api/v1/pub/appointments/${uuid}`,
    transform: (res) => transformAppointment(res?.data, res?.included),
  });
};
