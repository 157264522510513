import { configureApi } from 'react-reqq-lite';
import { get } from 'lodash';
import { toastError } from 'react-geek-toast';
import history from './history';

const store = configureApi({
  endpoint: process.env.REACT_APP_END_POINT,
  requestHeaders: () => {
    const token = localStorage.getItem('token');
    if (!token)
      return {
        'Content-Type': 'application/json',
      };
    return {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  },
  timeout: 180000,
  // cacheStorage: 'WEBSQL',
  onError: (err) => {
    if (window.location.pathname.indexOf('/scan') > -1) {
      return;
    }
    const err_message = get(err, 'response.message');
    if (err.status === 422) {
      const message = get(
        err,
        `response.errors.${Object.keys(get(err, 'response.errors'))[0]}.0`
      );
      toastError(message || 'Unprocessable Entity!');
      return;
    }
    if (err.status === 400) {
      toastError(err_message || 'Bad Request!');
      return;
    }
    if (err.status === 500) {
      toastError(err_message || 'Internal Server Error!');
      return;
    }
    if (err.status === 403) {
      toastError(err_message || 'Forbidden!');
      if (
        get(err, 'response.error') === 'token_missing' ||
        get(err, 'response.error') === 'token_mismatch' ||
        get(err, 'response.error') === 'token_expired' ||
        get(err, 'response.error') === 'token_invalid'
      )
        localStorage.removeItem('token');
        localStorage.removeItem('refno');
        history.push('/');
      return;
    }
    // if (err.status === 0) {
    //   toastError('Unable to connect to web service');
    //   return;
    // }
    console.warn('unhandled error', err); // eslint-disable-line
  },
});

export default store;
