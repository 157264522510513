export const AUTHENTICATION = 'AUTH/authentication';
export const LOGIN = 'AUTH/login';
export const ADMIN_LOGIN = 'AUTH/admin_login';

export const REGISTER_SEND_OTP = 'AUTH/register_send_otp';
export const RESEND_SEND_OTP = 'AUTH/resend_send_otp';
export const REGISTER_CONFIRM_OTP = 'AUTH/register_confirm_otp';

export const FORGOT_PASSWORD = 'AUTH/forgot_password';

export const ADMIN_REGISTER = 'ADMIN/admin_register';
export const ADMIN_VERIFY_EMAIL = 'ADMIN/admin_verify_email';
export const ADMIN_FORGOT_PASSWORD = 'ADMIN/admin_forgot_password';
export const ADMIN_RESET_PASSWORD = 'ADMIN/admin_reset_password';
