import React from 'react';
// import PropTypes from 'prop-types';
import logo from 'assets/img/logo.png';
import { Link } from 'react-router-dom';
import bg_img from 'assets/img/banner-register.jpg';
import RegisterForm from './register/RegisterForm';
import Footer from './Footer';
import TicketHistory from './components/TicketHistory';
import ZooSchedule from './components/ZooSchedule';
import ZooReminder from './components/ZooReminder';
import FeesTable from './components/FeesTable';

function BookAppointment() {
  return (
    <div>
      <div className="flex bg-gradient-to-b from-gray-50 to-gray-200 relative">
        <div className="absolute h-full left-0 w-full sm:w-1/2" />
        <div className="grid grid-cols-1 sm:grid-cols-2 relative z-10">
          <div className="bg-gradient-to-t from-primary-900 to-[#0A71BF00] relative">
            <div
              className="absolute h-full w-full bg-fill bg-center"
              style={{ backgroundImage: `url(${bg_img})` }}
            />
            <div className="sm:p-16 md:px-5 lg:px-10 px-5 pb-10 relative z-20">
              <div className="mb-16">
                <Link to="/">
                  <img className="h-28 inline-block" src={logo} alt="Brand" />
                </Link>
              </div>
              <div className="text-white space-y-4">
                <div className="text-2xl font-semibold">
                  Welcome to Manila Zoo!
                </div>
                <div className="space-y-1">
                  {/* <div className="font-semibold">Our Schedule:</div> */}
                  <ZooSchedule />
                </div>
                <div>
                  <FeesTable />
                </div>
                <div>
                  <ZooReminder />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-100 sm:bg-transparent">
            <div className="sm:p-16 md:px-5 px-4 py-10 relative z-20 mx-auto">
              <div className="text-center max-w-sm mx-auto">
                <div className="text-primary-500 font-black font-title text-3xl">
                  Get a ticket
                </div>
                <div className="font-light">
                  Fill out the required fields to get your ticket.
                </div>
              </div>
              <div className="mt-4">
                <TicketHistory />
              </div>
              <div className="mt-8">
                <RegisterForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer noTrees />
    </div>
  );
}

BookAppointment.propTypes = {};

export default BookAppointment;
