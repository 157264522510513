import React, { useEffect } from 'react'
import { req, useApiGet } from 'react-reqq-lite';

function FeesTable() {
  const fees = useApiGet('FEES', null);
  useEffect(() => {
    req.get({
      key: 'FEES',
      url: '/api/v1/pub/fees',
      transform: (raw) => {
        const finder = (code) => (raw?.data || [])
          .find((x) => x?.attributes?.code === code)?.attributes?.value    
       return {
          adult_and_children_ncr: finder('FEE_NCR_ADULT'),
          adult_and_children_outside: finder('FEE_NON-NCR_ADULT'),
          student_ncr: finder('FEE_NCR_STUDENT'),
          student_outside: finder('FEE_NON-NCR_STUDENT'),
          // pwd_senior: '20% Discount on prescribed fees',
          // pwd_senior_outside: '20% Discount on prescribed fees',
          pwd_senior: '120',
          pwd_senior_outside: '240',
          // two_and_below: 'Free',
          // two_and_below_outside: 'Free',
          two_and_below_resident_outside: 'Free',
          manila_employee: finder('FEE_MANILA_EMPLOYEE'),
        }
      },
    });
  }, []);
  
  if (!fees) return null;
  return (
    <div className="font-light">
      <div className="text-xs py-2">
        <span className="font-semibold">Rates: </span>
          <i>(updated September 14, 2023):</i>
      </div>
      <table className="table-auto w-full text-sm md:text-base">
        <tbody>
          <tr>
            <td className="border">
              &nbsp;
            </td>
            <td className="border text-center">
              <b>Manila Residents</b>
            </td>
            <td className="border text-center">
              <b>Non-Manila Residents</b>
            </td>
          </tr>
          <tr>
            <td className="border px-2 py-2">
              Adults and Children three (3) years old and above
            </td>
            <td className="border text-center py-2">₱{fees?.adult_and_children_ncr || "0.00"}</td>
            <td className="border text-center py-2">₱{fees?.adult_and_children_outside || "0.00"}</td>
          </tr>
          <tr>
            <td className="border px-2 py-2">
              Students
            </td>
            <td className="border text-center py-2">₱{fees?.student_ncr || "0.00"}</td>
            <td className="border text-center py-2">₱{fees?.student_outside || "0.00"}</td>
          </tr>
          <tr>
            <td className="border px-2 py-2">
              Senior Citizens and PWDs <i>(Discounted Rate of 20%)</i>
            </td>
            <td className="border text-center py-2">₱{fees?.pwd_senior || "0.00"}</td>
            <td className="border text-center py-2">₱{fees?.pwd_senior_outside || "0.00"}</td>
          </tr>
          <tr>
            <td className="border px-2 py-2">
              Children two (2) years old and below <i>(registration not required)</i>
            </td>
            <td className="border text-center py-2" colSpan="2">{fees?.two_and_below_resident_outside}</td>
          </tr>
          <tr>
            <td className="border px-2 py-2">
              Manila LGU Employese/Teachers under Manila Division of City Schools
            </td>
            <td className="border text-center py-2" colSpan="2">₱{fees?.manila_employee || "0.00"}</td>
          </tr>
        </tbody>
      </table>
      <div className="text-xs py-2">
        <span className="font-semibold">Note: </span>
          <i>Senior Citizens of Manila with valid OSCA IDs are FREE to visit Manila Zoo
            <span className="font-semibold whitespace-nowrap"> every Wednesday</span>
          </i>
      </div>
    </div>
    // <div className="text-yellow-400">
    //   The Manila Zoological and Botanical Garden is <b className="font-semibold">temporarily closed</b> due to its continuous development. Thank you for your understanding
    // </div>
  )
}

export default FeesTable