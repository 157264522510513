import React from 'react';
import PropTypes from 'prop-types';
import { getPrevTickets } from 'manila-zoo/actions';
import { showModal } from 'modules/components/Modal';
import { Link } from 'react-router-dom';

function TicketHistoryModal({ list, onClose }) {
  return (
    <div className="p-5">
      <div className="font-bold text-center text-lg">Ticket History</div>
      <ul className="list-disc pl-6">
        {list.map((item) => (
          <li key={item.refno}>
            <Link className="hover:underline" to={`/appointment/${item.refno}`} onClick={onClose}>
              <span>[{item.schedule_label || '-'}] {item.label || 'n/a'}</span>
            </Link>
          </li>
        ))}
      </ul>
      <div className="mt-4 text-center">
        <button className="btn" type="button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  )
}

TicketHistoryModal.propTypes = {
  list: PropTypes.instanceOf(Array).isRequired,
  onClose: PropTypes.func.isRequired,
}

function TicketHistory() {
  const list = React.useMemo(() => {
    const temp = getPrevTickets();
    return temp.map((x) => {
      const [refno, schedule_label, label] = x.split(':');
      return {
        refno, schedule_label, label
      };
    })
  }, []);
  const handleShow = (e) => {
    e.preventDefault();
    showModal({
      title: false,
      className: 'modal-md',
      content: (onClose) => (
        <TicketHistoryModal list={list} onClose={onClose} />
      ),
    });
  }
  if (list.length < 1) return null;
  return (
    <div className="text-center text-sm">
      Lost your ticket? <a className="hover:underline font-semibold" href="/" onClick={handleShow}>View Ticket History</a>
    </div>
  );
}

TicketHistory.propTypes = {}

export default TicketHistory
