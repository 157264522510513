import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { HiTrash, HiPencil } from 'react-icons/hi';
import FormInput from 'modules/forms/FormInput';
import FormSelect from 'modules/forms/FormSelect';
import FormAddress from 'modules/forms/FormAddress';
import { toastWarning } from 'react-geek-toast';
import { omit, each, pick } from 'lodash';
import FormIsManila from 'modules/forms/FormIsManila';

const NO_ADDRESS_INPUT = true;

const GENDER_OPTIONS = [
  { label: 'MALE', value: 'MALE' },
  { label: 'FEMALE', value: 'FEMALE' },
];

const toUpperCase = (obj, keys) => {
  const newObj = {};
  each(obj, (v, k) => {
    newObj[k] =
      keys.indexOf(k) > -1 && typeof v === 'string' ? v.toUpperCase() : v;
  });
  return newObj;
};

const EDITABLE = true;

function PersonForm({ onChange, onRemove, value, canRemove, isSelf }) {
  const [edit, setEdit] = React.useState(value?.is_edit);
  const [form, setForm] = React.useState(value);
  const handleCancel = () => {
    if (!form.first_name || !form.age) {
      onRemove();
    }
    setEdit(false);
    setForm(value);
  };

  const isSenior = +form?.age > 59;

  const handleSave = () => {
    if (!form.first_name || !form.age || !form?.municipality_code) {
      toastWarning('Please complete all fields');
      return;
    }
    onChange(omit(toUpperCase(form, ['first_name', 'last_name']), ['is_edit']));
    setEdit(false);
  };
  if (!edit) {
    return (
      <div className="text-sm flex py-2 items-center">
        <div className="flex-1 font-light pr-3 flex justify-between items-center">
          <div>
            <div
              className={cn(
                'text-gray-900 font-semibold',
                isSelf ? 'italic' : ''
              )}
            >
              For {value?.first_name} {value?.last_name}
            </div>
            <div className="text-xs">
              {value?.age ? <span>{value?.age}y/o</span> : null}
              {value?.province_code === '133900000' ? (
                <span> (Manila Resident)</span>
              ) : (
                <span> (Non-Resident)</span>
              )}
              <span>{value?.age >= 60 ? ' (Senior Citizen)' : ''}</span>
              {value?.is_student ? <span> (Student)</span> : null}
              {value?.is_pwd ? <span> (PWD)</span> : null}
              {value?.is_manila_employee ? (
                <span> (Manila LGU Employee)</span>
              ) : null}
            </div>
          </div>
          <div className="font-semibold text-gray-800">&times;1</div>
        </div>
        {EDITABLE ? (
          <div className="flex-shrink-0 space-x-1">
            <button
              className="text-gray-500 p-2 rounded-full hover:bg-gray-50 hover:text-primary-500 transition"
              type="button"
              onClick={() => setEdit(true)}
            >
              <HiPencil />
            </button>
            {canRemove ? (
              <button
                className="text-gray-500 p-2 rounded-full hover:bg-gray-50 hover:text-red-500 transition"
                type="button"
                onClick={onRemove}
              >
                <HiTrash />
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }

  const handleChangeAge = (v) => {
    const { age } = v({});
    const other =
      +age > 59
        ? {
            is_student: false,
            is_pwd: false,
            is_manila_employee: false,
          }
        : {};
    setForm({
      ...form,
      ...other,
      age,
    });
  };

  return (
    <div className="pt-2">
      <div className="rounded-lg p-3 bg-gray-50" style={{ marginTop: -1 }}>
        <div className="grid grid-cols-2 gap-3">
          <FormInput
            name="first_name"
            onChange={setForm}
            value={form.first_name}
            icon={false}
            label="First Name"
            className="uppercase"
          />
          <FormInput
            name="last_name"
            onChange={setForm}
            value={form.last_name}
            icon={false}
            label="Last Name"
            className="uppercase"
          />
          <FormSelect
            name="gender"
            onChange={setForm}
            value={form.gender}
            label="Gender"
            options={GENDER_OPTIONS}
          />
          <FormInput
            name="age"
            onChange={handleChangeAge}
            value={form.age}
            icon={false}
            label="Age"
            type="number"
          />
          {NO_ADDRESS_INPUT ? (
            <div className="col-span-2">
              <FormIsManila onChange={setForm} value={form} />
            </div>
          ) : (
            <FormAddress
              onChange={setForm}
              value={pick(form, [
                'region_code',
                'province_code',
                'municipality_code',
                'barangay_code',
              ])}
              // required
            />
          )}
          {/* {!isSelf ? (
            <div className="col-span-2">
              <label className="flex items-center space-x-2 text-primary-500">
                <input
                  className="form-checkbox rounded h-5 w-5 border"
                  type="checkbox"
                  onChange={({ target }) =>
                    setForm((state) => ({
                      ...state,
                      is_same_as_main: target.checked,
                    }))
                  }
                  checked={form.is_same_as_main}
                />
                <span className="font-semibold text-sm">Family Member</span>
              </label>
            </div>
          ) : null}
          {!form.is_same_as_main ? (
            <FormAddress
              onChange={setForm}
              value={pick(form, [
                'region_code',
                'province_code',
                'municipality_code',
                'barangay_code',
              ])}
              required
            />
          ) : null} */}
          <div className="space-y-1">
            <div className="text-sm italic">Please check if apply:</div>
            <label className="flex items-center space-x-2 text-primary-500">
              <input
                disabled={isSenior}
                className="form-checkbox rounded disabled:bg-gray-50 h-5 w-5 border"
                type="checkbox"
                onChange={({ target }) =>
                  setForm((state) => ({
                    ...state,
                    is_pwd: false,
                    is_manila_employee: false,
                    is_student: target.checked,
                  }))
                }
                checked={!!form?.is_student}
              />
              <span className="font-semibold text-sm">Student</span>
            </label>
            <label className="flex items-center space-x-2 text-primary-500">
              <input
                disabled={isSenior}
                className="form-checkbox rounded disabled:bg-gray-50 h-5 w-5 border"
                type="checkbox"
                onChange={({ target }) =>
                  setForm((state) => ({
                    ...state,
                    is_student: false,
                    is_manila_employee: false,
                    is_pwd: target.checked,
                  }))
                }
                checked={!!form?.is_pwd}
              />
              <span className="font-semibold text-sm">PWD</span>
            </label>
            <label className="flex items-center space-x-2 text-primary-500">
              <input
                disabled={isSenior}
                className="form-checkbox rounded disabled:bg-gray-50 h-5 w-5 border"
                type="checkbox"
                onChange={({ target }) =>
                  setForm((state) => ({
                    ...state,
                    is_student: false,
                    is_pwd: false,
                    is_manila_employee: target.checked,
                  }))
                }
                checked={!!form?.is_manila_employee}
              />
              <span className="font-semibold text-sm">Manila LGU Employee</span>
            </label>
            <label className="flex items-center space-x-2 text-primary-500">
              <input
                disabled
                className="form-checkbox rounded disabled:bg-gray-50 h-5 w-5 border"
                type="checkbox"
                onChange={() => {}}
                checked={isSenior}
              />
              <span className="font-semibold text-sm">Senior Citizen</span>
            </label>
          </div>
          <div className="col-span-2 flex justify-end space-x-3">
            <button
              className="text-gray-500 font-bold text-sm px-3 py-2"
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="text-primary-500 font-bold text-sm px-3 py-2"
              type="button"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

PersonForm.defaultProps = {
  canRemove: true,
  isSelf: false,
};

PersonForm.propTypes = {
  // count: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  canRemove: PropTypes.bool,
  isSelf: PropTypes.bool,
};

export default PersonForm;
