/* eslint-disable react/no-array-index-key */
import React from 'react';
import { isEmpty } from 'lodash';
// import PropTypes from 'prop-types';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import {
  useAppointmentByRefno,
  // useBookAppointment,
  useCheckoutAppointment,
} from 'manila-zoo/hooks';
import logo from 'assets/img/logo.png';
import { HiCheck } from 'react-icons/hi';
import { DetailItem } from './SetSchedule';

function Checkout() {
  const history = useHistory();
  const location = useLocation();
  const [isLoading, data] = useAppointmentByRefno(location?.state?.refno);
  const payload = location?.state?.payload;
  const schedule = location?.state?.schedule;
  const [isSuccess, setIsSuccess] = React.useState(false);
  // const [isBooking, bookAppointment] = useBookAppointment();
  const [isCheckout, checkout] = useCheckoutAppointment();
  const handleBack = () => {
    history.push((location.pathname || '').replace('/checkout', ''));
  };
  const handleCheckout = () => {
    checkout(location?.state?.refno, () => {
      window.location.reload();
      setIsSuccess(true);
    });
    // if (data?.status === 'PENDING') {
    //   checkout(location?.state?.refno, () => {
    //     setIsSuccess(true);
    //   });
    //   return;
    // }
    // bookAppointment(location?.state?.refno, payload, () => {
    //   checkout(location?.state?.refno, () => {
    //     setIsSuccess(true);
    //   });
    // });
  };

  const handleClear = () => {
    localStorage.clear();
    history.push('/');
  };

  const actionLabel = React.useMemo(() => {
    if (isCheckout) return 'Please wait...';
    // if (isBooking) return 'Processing...';
    return 'Checkout';
  }, [isCheckout]);
  if ((['PENDING', 'PAID']).indexOf(data?.status) > -1)
    return <Redirect to={(location.pathname || '').replace('/checkout', '')} />;
  if (isLoading || isEmpty(data)) {
    return (
      <div className="flex flex-col min-h-full bg-gradient-to-b from-white via-primary-50 to-primary-100 relative">
        <div className="py-4 mx-auto">
          <img className="h-14" src={logo} alt="brand" />
        </div>
        <div className="shadow-lg rounded-xl bg-white w-full max-w-md mx-auto flex flex-col space-y-4 p-8 justify-center items-center">
          <div>{isLoading ? 'Loading...' : 'Appointment not found!'}</div>
          {!isLoading ? (
            <div className="mt-4">
              <button
                className="btn primary sm"
                type="button"
                onClick={handleClear}
              >
                Go back to Homepage
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  if (!localStorage.getItem('token')) {
    return <Redirect to="/" />;
  }
  if (!payload || !location?.state?.refno) {
    return <Redirect to="/" />;
  }
  if (isSuccess) {
    return (
      <div className="flex flex-col min-h-full bg-gradient-to-b from-gray-50 to-gray-200 relative">
        <div className="py-4 mx-auto">
          <img className="h-20" src={logo} alt="brand" />
        </div>
        <div className="shadow-primary-lg rounded-xl bg-white w-full max-w-md mx-auto flex flex-col space-y-4 p-8">
          <div className="check mx-auto">
            <HiCheck className="w-20 h-20 flex-shrink-0 border-8 border-green-100 p-3 text-white bg-gradient-to-tr from-green-300 to-green-500 rounded-full mx-auto mb-3" />
          </div>
          <div className="text-primary-500 font-black font-title text-xl mb-4 text-center">
            Processing Appointment
          </div>
          <div className="text-center">
            Please wait...
          </div>
          {/* <div className="text-center">
            Go back to {' '}
            <Link to="/">homepage</Link>.
          </div> */}
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col min-h-full bg-gradient-to-b from-gray-50 to-gray-200 relative">
      <div className="py-4 mx-auto">
        <img className="h-20" src={logo} alt="brand" />
      </div>
      <div className="shadow-primary-lg rounded-xl bg-white w-full max-w-md mx-auto flex flex-col space-y-4 p-8">
        <div className="text-primary-500 font-black font-title text-xl mb-4 text-center">
          Appointment Summary
        </div>
        <div className="text-sm">
          {schedule ? (
            <>
              <div className="bg-gray-100 py-1 px-3 font-semibold text-gray-600">
                Appointment Details
              </div>
              <div className="divide-y divide-gray-50 mx-3">
                <DetailItem
                  label="Date"
                  value={schedule?.schedule_date || '-'}
                />
                <DetailItem label="Location" value={schedule?.address || '-'} />
                {/* <DetailItem label="Type" value={schedule?.schedule_type || '-'} /> */}
              </div>
            </>
          ) : null}
          <div className="bg-gray-100 py-1 px-3 font-semibold text-gray-600">
            Ticket Details
          </div>
          <div className="divide-y divide-gray-50 mx-3">
            {(payload?.person || []).map((item, i) => (
              <DetailItem
                key={i}
                label={`Ticket for ${item?.first_name} ${item?.last_name}`}
                value={(
                  <>
                  <span>{item?.age >= 60 ? ' (Senior Citizen)' : ''}</span>
                  {item?.is_student ? <span> (Student)</span> : null}
                  {item?.is_pwd ? <span> (PWD)</span> : null}
                  {item?.is_manila_employee ? <span> (Manila LGU Employee)</span> : null}
                  </>
                )}
              />
            ))}
            <div className="flex justify-between py-2">
              <div className="font-bold text-lg">TOTAL AMOUNT</div>
              <div className="font-bold text-lg">{data?.total_amount || '0.00'}</div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3">
          <button
            className="btn light w-full"
            type="button"
            onClick={handleBack}
          >
            Back
          </button>
          <button
            className="btn primary w-full"
            type="submit"
            onClick={handleCheckout}
            disabled={isCheckout}
          >
            {actionLabel}
          </button>
        </div>
      </div>
    </div>
  );
}

Checkout.propTypes = {};

export default Checkout;
