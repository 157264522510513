/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { pick } from 'lodash';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { req } from 'react-reqq-lite';
import Alerts from 'manila-zoo/Alerts';
import logo from 'assets/img/logo.png';
import { usePersistState } from 'helper';
import { HiArrowLeft, HiPlusCircle } from 'react-icons/hi';
import { toastError } from 'react-geek-toast';
import { useHistory, useLocation } from 'react-router-dom';
import FormAppointmentSchedule from 'appointment-module/components/FormAppointmentSchedule';
import { useBookAppointment } from 'manila-zoo/hooks';
import Footer from 'manila-zoo/Footer';
import { clearAppointment } from 'manila-zoo/actions';
import PersonForm from './PersonForm';

const useGetAvailableFacility = () => {
  const [id, setId] = React.useState('');
  React.useEffect(() => {
    req.get({
      key: 'SAMPLE',
      url: `/api/v1/me/search-facilities`,
      params: {
        per_page: 1,
      },
      onSuccess: (res) => {
        setId(res?.response?.data?.[0]?.attributes?.uuid || '');
      },
    });
  }, [setId]);
  return [id];
};

const initForm = {
  first_name: '',
  last_name: '',
  gender: 'MALE',
  age: '',
  is_same_as_main: true,
  region_code: '',
  province_code: '',
  municipality_code: '',
  barangay_code: '',
  is_manila_employee: false,
  is_pwd: false,
  is_student: false,
};

export const DetailItem = ({ label, value, className }) => (
  <div className={cn('flex justify-between py-2', className)}>
    <div className="font-light pr-3">{label}</div>
    <div className="font-semibold text-right">{value}</div>
  </div>
);

DetailItem.defaultProps = {
  className: '',
};

DetailItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const isValidAge = () => true; // (person) => +person?.age > 11; // ALLOW ANY AGE

const ONE_TO_ONE_MODE = true;

function SetSchedule({ refno }) {
  const location = useLocation();
  const [schedule, setSchedule] = React.useState(null);
  const [persons, setPersons] = usePersistState(
    'booking-form',
    [{ ...initForm, ...(location?.state?.main || { is_edit: true }) }],
    { keepOnUnmount: true }
  );
  const [facilityId] = useGetAvailableFacility();
  const history = useHistory();

  const areAgesValid = persons.every(isValidAge);

  const [isBooking, bookAppointment] = useBookAppointment();

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      schedule_uuid: schedule?.id,
      person: persons.map((x) => ({
        ...pick(x, [
          'first_name',
          'last_name',
          'gender',
          'age',
          'region_code',
          'province_code',
          'municipality_code',
          'barangay_code',
          'is_student',
          'is_pwd',
          'is_manila_employee',
          'id_photo_url',
        ]),
        // ...(x?.is_same_as_main ? pick(location?.state?.main || persons[0], [
        //   'region_code',
        //   'province_code',
        //   'municipality_code',
        //   'barangay_code',
        // ]) : {}),
      })),
    };
    // history.push({
    //   pathname: `/appointment/${refno}/checkout`,
    //   state: {
    //     refno,
    //     payload,
    //     schedule,
    //   },
    // });
    bookAppointment(refno, payload, () => {
      history.push({
        pathname: `/appointment/${refno}/checkout`,
        state: {
          refno,
          payload,
          schedule,
        },
      });
    });
  };
  const handleAddMore = () => {
    setPersons((state) => state.concat([{ ...initForm, is_edit: true }]));
  };
  const handleChangePerson = (index) => (newValue) => {
    setPersons((state) => state.map((x, i) => (index === i ? newValue : x)));
  };
  const handleRemove = (index) => () => {
    setPersons((state) => state.filter((x, i) => index !== i));
  };
  const canProceed =
    persons.length > 0 &&
    schedule?.available_slots >= persons.length &&
    areAgesValid;

  const handleCancel = (e) => {
    e.preventDefault();
    clearAppointment();
  };

  const canAdddMore = (persons || []).length < 10;

  useEffect(() => {
    const first = persons?.[0];
    if (
      (persons || []).length < 1 ||
      (first?.is_same_as_main && (!first?.first_name || !first?.last_name))
    ) {
      clearAppointment(null, true);
      toastError('Invalid URL!');
    }
  }, [persons]);

  return (
    <div className="min-h-full flex flex-col">
      <div className="flex bg-gradient-to-b from-gray-50 to-gray-200 relative flex-1">
        <div className="container mx-auto">
          <div className="py-4">
            <img className="h-14" src={logo} alt="brand" />
          </div>
          <div className="mb-4">
            <a
              className="flex space-x-1 items-center"
              href="/"
              onClick={handleCancel}
            >
              <HiArrowLeft className="w-5 h-5 flex-shrink-0" />
              <span>Go back</span>
            </a>
          </div>
          <div className="grid sm:grid-cols-5 grid-cols-1 gap-3 pb-10">
            <div className="col-span-1 sm:col-span-3 shadow-primary-lg rounded-none sm:rounded-xl bg-white">
              <div className="p-5 w-full" style={{ minHeight: 505 }}>
                <div className="text-primary-500 font-black font-title text-xl mb-4">
                  Set Appointment
                </div>
                {!facilityId ? (
                  <div className="p-12">
                    <div className="text-center text-gray-700 font-bold">
                      Loading...
                    </div>
                  </div>
                ) : (
                  <FormAppointmentSchedule
                    facilityId={facilityId}
                    onChange={setSchedule}
                  />
                )}
              </div>
            </div>
            <div className="col-span-1 sm:col-span-2 shadow-primary-lg rounded-none sm:rounded-xl bg-white min-h-[520px]">
              <div className="p-5 w-full text-gray-800">
                <div className="space-y-4">
                  <div className="text-primary-500 font-black font-title text-xl mb-4">
                    Appointment Details
                  </div>
                  <div className="text-sm">
                    <div className="bg-gray-100 py-1 px-3 font-semibold text-gray-600">
                      Appointment Details
                    </div>
                    <div className="divide-y divide-gray-50 mx-3">
                      <DetailItem
                        label="Date"
                        value={schedule?.schedule_date || '-'}
                      />
                      <DetailItem
                        label="Remaining"
                        value={schedule?.available_slots ?? '-'}
                      />
                      <DetailItem
                        className="min-h-[57px]"
                        label="Location"
                        value={schedule?.address || '-'}
                      />
                      <DetailItem
                        label="Type"
                        value={schedule?.schedule_type || '-'}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="bg-gray-100 py-1 px-3 font-semibold text-gray-600">
                      Guest Details
                    </div>
                    <div className="divide-y divide-gray-50 mx-3">
                      {persons.map((item, i) => (
                        <PersonForm
                          key={i}
                          count={i + 1}
                          onChange={handleChangePerson(i)}
                          value={item}
                          onRemove={handleRemove(i)}
                          canRemove={i !== 0}
                          isSelf={i === 0}
                        />
                      ))}
                    </div>
                  </div>
                  {ONE_TO_ONE_MODE && canAdddMore ? (
                    <div className="flex items-center">
                      <div className="border-t flex-1" />
                      <button
                        className="border border-gray-200 flex space-x-2 items-center rounded-full text-sm font-semibold text-primary-500 px-5 py-1 hover:bg-gray-50"
                        type="button"
                        onClick={handleAddMore}
                      >
                        <span>Add More</span>
                        <HiPlusCircle className="h-6 w-6" />
                      </button>
                      <div className="border-t flex-1" />
                    </div>
                  ) : null}
                  <div>
                    <Alerts />
                  </div>
                  <div className="pt-10">
                    <button
                      className="btn primary w-full"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={!canProceed || isBooking}
                    >
                      Set Appointment{/* for {persons.length} person/s */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer noTrees />
    </div>
  );
}

SetSchedule.propTypes = {
  refno: PropTypes.string.isRequired,
};

export default SetSchedule;
