import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { req, useApiGet } from 'react-reqq-lite';

const storage = {
  get: (key) => {
    return localStorage.getItem(key);
  },
  set: (key, value) => {
    localStorage.setItem(key, value);
  },
  remove: (key) => {
    localStorage.removeItem(key);
  },
};

export const useLocalization = () => {
  const [locale] = useState(storage.get('locale'));
  const current = useApiGet('LOCALE', locale);
  useEffect(() => {
    if (!locale) {
      storage.set('locale', 'PH');
      // req.get({
      //   key: 'LOCALE',
      //   url: '/api/v1/country-check',
      //   transform: (res) => res?.code,
      //   onSuccess: (res) => {
      //     if (res?.response?.code) storage.set('locale', res?.response?.code);
      //   },
      // });
      return;
    }
    req.set('LOCALE', locale);
  }, [locale]);
  const setter = useCallback((newValue) => {
    req.set('LOCALE', newValue);
    if (isEmpty(newValue)) {
      storage.remove('locale');
      return;
    }
    storage.set('locale', newValue);
  }, []);
  return [current, setter];
};
