/* eslint-disable react/no-array-index-key */
import React from 'react';
import cn from 'classnames';
// import videoPoster from 'assets/video/poster.jpg';
// import videoLanding from 'assets/video/manila-zoo-highlights.mp4';

// import slider1 from 'assets/img/sliders/1.jpg';
// import slider2 from 'assets/img/sliders/2.jpg';
// import slider3 from 'assets/img/sliders/3.jpg';
import slider4 from 'assets/img/sliders/4.jpg';
// import slider5 from 'assets/img/sliders/5.jpg';
import slider6 from 'assets/img/sliders/6.jpg';
import slider7 from 'assets/img/sliders/7.jpg';
import slider8 from 'assets/img/sliders/8.jpg';
// import slider9 from 'assets/img/sliders/9.jpg';
// import slider10 from 'assets/img/sliders/10.jpg';
import slider11 from 'assets/img/sliders/11.jpg';
import slider12 from 'assets/img/sliders/12.jpg';
// import slider13 from 'assets/img/sliders/13.jpg';
import slider14 from 'assets/img/sliders/14.jpg';
import slider15 from 'assets/img/sliders/15.jpg';
import slider16 from 'assets/img/sliders/16.jpg';
import slider17 from 'assets/img/sliders/17.jpg';
import slider18 from 'assets/img/sliders/18.jpg';
import slider19 from 'assets/img/sliders/19.jpg';
import slider20 from 'assets/img/sliders/20.jpg';

import imgMamal1 from 'manila-zoo/assets/animals/mamal_african-lion_panthera-leo.jpg';
import imgAvian9 from 'manila-zoo/assets/animals/avian_philippine-serpent-eagle_spilornis-holospilus.jpg';
import imgReptile5 from 'manila-zoo/assets/animals/reptile_philippine-crocodile_crocodylus-mindorensis.jpg';
import imgMamal2 from 'manila-zoo/assets/animals/mamal_asian-elephant_elephas-maximus.jpg';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { HiArrowRight, HiOutlineMap } from 'react-icons/hi';
import Header from './components/Header';
import Footer from './Footer';
import ZooSchedule from './components/ZooSchedule';
import { showGuidelinesModal } from './actions';

const SLIDERS = [
  slider4,
  slider14,
  slider16,
  slider11,
  slider19,
  slider20,
  slider6,
  slider12,
  slider17,
  slider8,
  slider15,
  slider7,
  slider18,
]

const useSlider = (count) => {
  const [state, setState] = React.useState(0);
  const timerRef = React.useRef();
  React.useEffect(() => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      const newState = state + 1;
      setState(newState > count ? 0 : newState);
    }, 4000);
    // }, 500);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [count, state, setState]);
  return [state];
};

function Landing() {
  const history = useHistory();
  const location = useLocation();
  const handleBook = () => {
    showGuidelinesModal(() => {
      history.push('/book-appointment');
    });
  };
  // const handleScrollTo = (id) => (e) => {
  //   e.preventDefault();
  //   const elem = document.getElementById(id);
  //   if (elem) elem.scrollIntoView({ behavior: 'smooth' });
  // };
  React.useEffect(() => {
    if (!location.hash) return;
    const elem = document.getElementById(
      (location.hash || '').replace('#', '')
    );
    if (elem) elem.scrollIntoView({ behavior: 'smooth' });
  }, [location.hash]);
  const [index] = useSlider(SLIDERS.length - 1);
  return (
    <div className="h-screen">
      <div className="bg-primary-500 h-screen relative">
        <div className="container mx-auto relative h-full flex items-end px-10">
          <Header />
          <div className="my-24 relative z-40 mx-auto">
            <div className="flex items-center flex-col md:flex-row space-x-0 sm:space-x-4 space-y-4 sm:space-y-0 pt-4">
              <button
                className="btn primary flex items-center space-x-4"
                type="button"
                onClick={handleBook}
              >
                <span>Get Ticket</span>
                <HiArrowRight className="w-5 h-5 flex-shrink-0" />
              </button>
              <a
                className="btn bg-transparent text-white hover:text-white flex items-center space-x-4"
                href="https://goo.gl/maps/Ly7am5fp9Q9RMDKp9"
                target="_blank"
                rel="noreferrer"
              >
                <span className="border-b-2 border-white">Location Map</span>
                <HiOutlineMap className="w-5 h-5 flex-shrink-0" />
              </a>
            </div>
          </div>
        </div>
        <div className="absolute left-0 right-0 bottom-0 top-0 bg-gradient-to-t from-[#000000cc] to-[#00000000] z-10" />
        <div className="absolute inset-0 z-0">
          {SLIDERS.map((item, i) => (
            <img
              key={i}
              src={item}
              alt=""
              className={cn(
                'absolute top-0 w-full h-screen object-cover duration-1000 transition-opacity ease-in-out',
                index === i ? 'opacity-100' : 'opacity-0'
              )}
            />
          ))}
        </div>
      </div>
      <div className="bg-primary-500">
        <div className="container mx-auto px-5 relative">
          <div className="absolute" id="visit" style={{ top: -120 }} />
          <div className="grid sm:grid-cols-2 grid-cols-1 items-center gap-4 sm:space-x-2 space-x-0 py-16">
            <div className="text-white space-y-4">
              <div className="text-white items-center">
                <div className="font-title text-4xl sm:text-5xl mb-2">
                  The New Manila Zoo
                </div>
                <div className="font-bold mb-3">
                  REDISCOVER AND EXPERIENCE THE NEWLY-RENOVATED AND MODERNIZED
                  MANILA ZOO
                </div>
                <p className="text-base">Discover. Learn. Get involved.</p>
                <ZooSchedule />
              </div>
              <h1 className="font-title text-4xl sm:text-5xl">
                Discover life. Interact.
              </h1>
              <p className="text-sm">
                The Manila Zoological and Botanical Garden is home to about a
                thousand animals from 90 species as of April 2015. Giving life
                to the modernized and improved Manila Zoo are a Bengal tiger,
                Malayan civet, monitor lizard and hippopotamus, among others,
                and many of which were born in captivity with three month-old
                juveniles recently born in April 2015.
              </p>
            </div>
            <div className="aspect-w-16 aspect-h-9">
              {/* <Player poster={videoPoster}>
                <source src="https://ucarecdn.com/496d3ba0-3d75-4d5b-b3d6-7932d15575d3/manilazoo.mp4" />
                <BigPlayButton position="center" />
                <LoadingSpinner />
              </Player> */}
              <iframe
                title="New Manila Zoo"
                src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fdtcamanila%2Fvideos%2F208371421512133%2F&show_text=false&width=560&t=0"
                width={560}
                height={314}
                style={{ border: 'none', overflow: 'hidden' }}
                scrolling="no"
                frameBorder={0}
                allowFullScreen
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-16">
        <div className="container mx-auto px-5 relative">
          <div className="absolute" id="animals" style={{ top: -120 }} />
          <div className="text-white space-y-4 pb-16 text-center">
            <h1 className="font-title text-5xl text-green-500">
              Explore our animals
            </h1>
            <div className="w-16 rounded-full h-1 mx-auto bg-green-500" />
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
            <div className="relative h-96 bg-gray-100 overflow-hidden rounded-2xl flex flex-col">
              <div className="relative z-10 text-white p-5 mt-auto bg-gradient-to-t from-yellow-500 to-[#ffffff00]">
                <div className="text-3xl font-title">Panthera Leo</div>
                <p className="text-base">African Lion</p>
              </div>
              <div className="absolute inset-0 z-0">
                <img
                  src={imgMamal1}
                  alt=""
                  className="object-cover w-full h-full"
                />
              </div>
            </div>
            <div className="relative h-96 bg-gray-100 overflow-hidden rounded-2xl flex flex-col mt-10">
              <div className="relative z-10 text-white p-5 mt-auto bg-gradient-to-t from-red-500 to-[#ffffff00]">
                <div className="text-3xl font-title">Spilornis Holospilus</div>
                <p className="text-base">Philippine Serpent Eagle</p>
              </div>
              <div className="absolute inset-0 z-0">
                <img
                  src={imgAvian9}
                  alt=""
                  className="object-cover w-full h-full"
                />
              </div>
            </div>
            <div className="relative h-96 bg-gray-100 overflow-hidden rounded-2xl flex flex-col">
              <div className="relative z-10 text-white p-5 mt-auto bg-gradient-to-t from-blue-500 to-[#ffffff00]">
                <div className="text-3xl font-title">Crocodylus Mindorensis</div>
                <p className="text-base">Philippine Crocodile</p>
              </div>
              <div className="absolute inset-0 z-0">
                <img
                  src={imgReptile5}
                  alt=""
                  className="object-cover w-full h-full"
                />
              </div>
            </div>
            <div className="relative h-96 bg-gray-100 overflow-hidden rounded-2xl flex flex-col mt-10">
              <div className="relative z-10 text-white p-5 mt-auto bg-gradient-to-t from-purple-500 to-[#ffffff00]">
                <div className="text-3xl font-title">Elephas Maximus</div>
                <p className="text-base">Asian Elephant</p>
              </div>
              <div className="absolute inset-0 z-0">
                <img
                  src={imgMamal2}
                  alt=""
                  className="object-cover w-full h-full"
                />
              </div>
            </div>
          </div>
          <div className="text-center mt-8">
            <Link className="text-xl font-bold" to="/animals">View More</Link>
          </div>
          <div className="text-center font-light text-xs mt-12">
            All life in the Manila Zoo are closely monitored and protected by
            the City Government of Manila, a group of Filipino botanists and
            zoologists.
          </div>
        </div>
      </div>
      <Footer getTicket />
    </div>
  );
}

Landing.propTypes = {};

export default Landing;
