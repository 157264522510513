import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useAuth } from 'modules/auth/hooks';
import { AlertMarker, ModalMarker } from 'modules/components/Modal';
import ToastMarker from 'react-geek-toast';
import ErrorBoundary from 'modules/partial/containers/ErrorBoundary';
// import Splash from 'modules/partial/containers/Splash';
// import MobileOnly from 'modules/partial/containers/MobileOnly';
// import AppointmentPreview from 'modules/appointment/containers/AppointmentPreview';
// import DocumentPreview from 'modules/document-preview/containers/DocumentPreview';
// import ViewCertificate from 'modules/certificate-preview/containers/ViewCertificate';
// import ViewDocument from 'modules/document-preview/containers/ViewDocument';
// import AppointmentRoutes from 'appointment-module/containers/AppointmentRoutes';
// import AppointmentApplication from 'appointment-module/containers/AppointmentApplication';
// import HealthPassApp from 'healthpass-modules/HealthPassApp';
import qs from 'query-string';
import Stage from 'modules/components/Stage';
import { useLocalization } from 'modules/hooks/localization';
import Language from 'modules/components/Language';
// import Private from './Private';
// import Public from './Public';
import ManilaZoo from 'manila-zoo';
import ReactGA from 'react-ga';

// const GuestLogbookContainer = lazy(() =>
//   import('modules/guest-logbook/containers/GuestLogbookContainer')
// );

// const AppPublic = lazy(() => import('./AppPublic'));

// const RedirectLogbook = () => {
//   const { uuid } = useParams();
//   return <Redirect to={`/app/guest/${uuid}`} />;
// };

function App() {
  useLocalization();
  const [isAppReady, /* isAuthenticated */] = useAuth();
  /* eslint-disable */
  const history = useHistory();
  const TRACKING_ID = process.env.REACT_APP_GOOGLE_KEY || '';
  // const [isMobile] = React.useState(
  //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  //     navigator.userAgent
  //   )
  // );

  const trackPageView = location => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  };

  React.useEffect(() => {
    if (TRACKING_ID !== '') {
      ReactGA.initialize(TRACKING_ID);
      trackPageView(history.location);
      history.listen(trackPageView);
    }
  }, []);

  React.useEffect(() => {
    const q = qs.parse(window.location.search);
    if (q.type) sessionStorage.setItem('app_type', q.type);
  }, []);
  // const isMobile = true;
  if (!isAppReady) return null;

  return (
    <ErrorBoundary>
      <Stage />
      <ToastMarker />
      <ModalMarker />
      <AlertMarker />
      <Language />
      <Switch>
        <Route component={ManilaZoo} />
      </Switch>
      {/* <Switch>
        <Route path="/app/healthpass" component={HealthPassApp} />
        <Route
          path="/certificate/:uuid/view"
          component={ViewCertificate}
        />
        <Route
          path="/medical-certificate/:document_number/view"
          component={ViewDocument}
        />
        <Route path="/appointment/:refno" component={AppointmentPreview} />
        <Route path="/med/:document_number" component={DocumentPreview} />
        <Route path="/g/logbook/:uuid" component={RedirectLogbook} />
        <Route path="/app/guest/:uuid">
          <Suspense fallback={<Splash />}>
            <GuestLogbookContainer />
          </Suspense>
        </Route>
        <Route path="/app/apply-history/:uuid">
          <Suspense fallback={<Splash />}>
            <AppointmentApplication />
          </Suspense>
        </Route>
        <Route path="/app/apply-vaccine/:id">
          <Suspense fallback={<Splash />}>
            <AppointmentRoutes />
          </Suspense>
        </Route>
        <Route path="/app">
          {isAuthenticated && <Redirect to="/" />}
          {isMobile ? (
            <Suspense fallback={<Splash />}>
              <AppPublic />
            </Suspense>
          ) : (
            <MobileOnly />
          )}
        </Route>
        <Route>
          {isAuthenticated ? (
            <Suspense fallback={<Splash />}>
              <Private />
            </Suspense>
          ) : (
            <>
              {!sessionStorage.getItem('app_type') ? (
                <Public />
              ) : (
                <Redirect to="/app" />
              )}
            </>
          )}
        </Route>
      </Switch> */}
    </ErrorBoundary>
  );
}

export default App;
