import React from 'react';
// import PropTypes from 'prop-types';
import { PrivacyPolicy } from './components/Guidelines';
import Header from './components/Header';
import Footer from './Footer';

function PrivacyPolicyPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container mx-auto">
        <Header />
      </div>
      <div className="pt-[120px]">
        <div
          className="container mx-auto"
          style={{ minHeight: 'calc(100vh - 120px - 350px - 160px)' }}
        >
          <div className="py-8 p-4">
            <PrivacyPolicy />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

PrivacyPolicyPage.propTypes = {};

export default PrivacyPolicyPage;
