import { isValidLatLng, toGeoArr } from 'helper';
import { useEffect, useMemo, useCallback } from 'react';
import { useApiGet, useApiLoading } from 'react-reqq-lite';
import moment from 'moment';
import {
  listFacilities,
  getFacilityById,
  getFacilitySchedules,
  applyVaccine,
  getVaccine,
} from './actions';
import {
  APPLY_VACCINE,
  FACILITIES_LIST,
  FACILITIES_PAGER,
  FACILITY,
  FACILITY_ERROR,
  FACILITY_SCHEDULE_BY_DATE,
  FACILITY_SCHEDULE_DATES,
  VACCINE_APPLICATION,
} from './constants';

export const useFacilitiesList = (filter) => {
  const isLoading = useApiLoading(FACILITIES_LIST, 'get');
  const list = useApiGet(FACILITIES_LIST, []);
  const pager = useApiGet(FACILITIES_PAGER, []);

  useEffect(() => {
    const { geoloc, ...newFilter } = filter;
    if (isValidLatLng(toGeoArr(geoloc))) {
      newFilter.geoloc = geoloc;
      newFilter['sort[distance]'] = 'ASC';
    }
    listFacilities(newFilter);
  }, [filter]);
  return [isLoading, list, pager];
};

export const useListAppointmentSchedules = (id) => {
  const isLoading = useApiLoading(FACILITY_SCHEDULE_DATES, 'get');
  const list = useApiGet(FACILITY_SCHEDULE_DATES, []);
  useEffect(() => {
    const DAYS_COUNT = 28
    if (!id) return;
    getFacilitySchedules(id, {
      from: moment().format('YYYY-MM-DD'), // moment().add(1, 'day').format('YYYY-MM-DD'),
      to: moment().add(DAYS_COUNT, 'day').format('YYYY-MM-DD'), // moment().add(3, 'months').format('YYYY-MM-DD'),
      per_page: DAYS_COUNT,
    });
  }, [id]);
  const formatted = useMemo(() => list.map((x) => new Date(x)), [list]);
  return [isLoading, formatted];
};

export const useListAppointmentSlotOptions = (date) => {
  const grouped = useApiGet(FACILITY_SCHEDULE_BY_DATE, {});
  const list = useMemo(
    () =>
      (grouped[moment(date).format('YYYY-MM-DD')] || []).map((x) => ({
        label: `${x.schedule_time}${
          x.available_slots < 1
            ? ' (Full)'
            : ` (${x.available_slots} Available)`
        }`,
        value: x.id,
        data: x,
        disabled: x.available_slots < 1,
      })),
    [grouped, date]
  );
  return [list];
};

export const useGetFacility = (id) => {
  const data = useApiGet(FACILITY, {});
  const error = useApiGet(FACILITY_ERROR, null);
  useEffect(() => {
    getFacilityById(id);
  }, [id]);
  return [data, error];
};

export const useVaccineApplication = (uuid) => {
  const isLoading = useApiLoading(VACCINE_APPLICATION, 'get');
  const data = useApiGet(VACCINE_APPLICATION, {});
  useEffect(() => {
    getVaccine(uuid);
  }, [uuid]);
  return [isLoading, data];
};

export const useApplyVaccine = () => {
  const isLoading = useApiLoading(APPLY_VACCINE, 'post');
  const submit = useCallback((payload, onSuccess) => {
    applyVaccine(payload, onSuccess);
  }, []);
  return [isLoading, submit];
};
