import React from 'react';
import { req, useApiGet } from 'react-reqq-lite';
import Cookie from 'js-cookie';
import * as c from './constants';

export const useAuth = () => {
  const auth = useApiGet(c.AUTHENTICATION, {});
  React.useEffect(() => {
    const token = Cookie.get('_token');
    const userType = Cookie.get('_userType') || 'PUBLIC';
    req.set(c.AUTHENTICATION, {
      isAuthenticated: !!token,
      type: userType,
    });
  }, []);
  return [typeof auth.isAuthenticated === 'boolean', auth.isAuthenticated];
};

export const temp = () => {};
