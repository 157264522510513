import React from 'react'
import PropTypes from 'prop-types';

import imgMamal1 from 'manila-zoo/assets/animals/mamal_african-lion_panthera-leo.jpg';
import imgMamal2 from 'manila-zoo/assets/animals/mamal_asian-elephant_elephas-maximus.jpg';
import imgMamal3 from 'manila-zoo/assets/animals/mamal_bengal-tiger_panthera-tigris-tigris.jpg';
import imgMamal4 from 'manila-zoo/assets/animals/mamal_celebes-black-ape_macaca-nigra.jpg';
import imgMamal5 from 'manila-zoo/assets/animals/mamal_hebra_equus-caballus-x-equus-burchelli-antiquorum.jpg';
import imgMamal6 from 'manila-zoo/assets/animals/mamal_philippine-deer_cervus-marianus.jpg';
import imgMamal7 from 'manila-zoo/assets/animals/mamal_siberian-tiger_panthera-tigris-altaica.jpg';

import imgAvian1 from 'manila-zoo/assets/animals/avian_bleeding-heart-pigeon_gallicolumba-luzonica.jpg';
import imgAvian2 from 'manila-zoo/assets/animals/avian_blue-peafowl_pavo-cristatus.jpg';
import imgAvian3 from 'manila-zoo/assets/animals/avian_brahminy-kite_haliastus-indus.jpg';
import imgAvian4 from 'manila-zoo/assets/animals/avian_doubled-wattled-cassowary_casuarius-casuarius.jpg';
import imgAvian5 from 'manila-zoo/assets/animals/avian_egret_egretta-alba.jpg';
import imgAvian6 from 'manila-zoo/assets/animals/avian_eurasia-collared-dove_streptopelia-decaocto.jpg';
import imgAvian7 from 'manila-zoo/assets/animals/avian_multi-coloured-stork_ibis-Leucophalus.jpg';
import imgAvian8 from 'manila-zoo/assets/animals/avian_palawan-pheasant_poyplectron-emphenum.jpg';
import imgAvian9 from 'manila-zoo/assets/animals/avian_philippine-serpent-eagle_spilornis-holospilus.jpg';
import imgAvian10 from 'manila-zoo/assets/animals/avian_philippine-swamphen_porphyrio-porphyria.jpg';
import imgAvian11 from 'manila-zoo/assets/animals/avian_severe-macaw_ara-severa-x-ara-auricollis.jpg';
import imgAvian12 from 'manila-zoo/assets/animals/avian_sulfur-crested-cockatoo_cacatua-galerita.jpg';

import imgReptile1 from 'manila-zoo/assets/animals/reptile_african-ball-python_python-regius.jpg';
import imgReptile2 from 'manila-zoo/assets/animals/reptile_burmese-python_python-mulorus-bivitatus.jpg';
import imgReptile3 from 'manila-zoo/assets/animals/reptile_elongata-tortoise_geochelone-elongate.jpg';
import imgReptile4 from 'manila-zoo/assets/animals/reptile_monitor-lizard_varannus-marmoratus.jpg';
import imgReptile5 from 'manila-zoo/assets/animals/reptile_philippine-crocodile_crocodylus-mindorensis.jpg';
import imgReptile6 from 'manila-zoo/assets/animals/reptile_philippine-spitting-cobra_naja-philippinensis.jpg';
import imgReptile7 from 'manila-zoo/assets/animals/reptile_pig-nosed-turtle_carettochelys-insculpta.jpg';
import imgReptile8 from 'manila-zoo/assets/animals/reptile_rat-snake_elaphe-spp.jpg';
import imgReptile9 from 'manila-zoo/assets/animals/reptile_red-iguana_iguana-iguana.jpg';
import imgReptile10 from 'manila-zoo/assets/animals/reptile_reticulated-python_python-reticulatus.jpg';
import imgReptile11 from 'manila-zoo/assets/animals/reptile_sail-fin-lizard_hydrosaurus-pustulatus.jpg';

const MAP_BG_COLOR = {
  yellow: 'bg-yellow-400',
  red: 'bg-red-400',
  purple: 'bg-purple-400',
  blue: 'bg-blue-400',
}

const MAP_FROM_COLOR = {
  yellow: 'from-yellow-500',
  red: 'from-red-500',
  purple: 'from-purple-500',
  blue: 'from-blue-500',
}

const Animal = ({ name, type, img, color }) => {
  return (
    <div className={`relative overflow-hidden rounded-2xl flex flex-col ${img ? 'h-96 bg-gray-100' : `h-24 ${MAP_BG_COLOR[color]}`}`}>
      <div className={`relative z-20 text-white p-5 mt-auto bg-gradient-to-t to-[#ffffff00] ${MAP_FROM_COLOR[color]}`}>
        <div className="text-lg font-title">{type || 'Unknown'}</div>
        <p className="text-base font-bold leading-4 uppercase">{name || 'Nameless'}</p>
      </div>
      <div className="absolute inset-0 z-10">
        <img
          src={img}
          alt=""
          className="object-contain w-full h-full"
        />
      </div>
      <div className="absolute inset-0 z-0 isolate blur transform scale-110">
        <img
          src={img}
          alt=""
          className="object-cover w-full h-full"
        />
      </div>
    </div>
  )
}

Animal.defaultProps = {
  img: null
};

Animal.propTypes = {
  img: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string
  ]),
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
}

function Animals() {
  return (
    <div className="space-y-4">
      <div className="text-white space-y-4 pb-4 text-center">
        <h1 className="pt-8 font-title text-4xl text-yellow-500">
          Mammals
        </h1>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
        <Animal name="African Lion" type="Panthera Leo" img={imgMamal1} color="yellow" />
        <Animal name="Asian Elephant" type="Elephas Maximus" img={imgMamal2} color="yellow" />
        <Animal name="Bengal Tiger" type="Panthera Tigris Tigris" img={imgMamal3} color="yellow" />
        <Animal name="Celebes Black Ape" type="Macaca Nigra" img={imgMamal4} color="yellow" />
        <Animal name="Hebra" type="Equus Caballus x Equus Burchelli Antiquorum" img={imgMamal5} color="yellow" />
        <Animal name="Philippine Deer" type="Cervus Marianus" img={imgMamal6} color="yellow" />
        <Animal name="Siberian Tiger" type="Panthera Tigris Altaica" img={imgMamal7} color="yellow" />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
        <Animal name="Philippine Monkey" type="Macaca Fasicularis Philippinensis" img={null} color="yellow" />
        <Animal name="Philippine Palm Civet" type="Paradoxorus Philipinensis" img={null} color="yellow" />
      </div>

      <div className="text-white space-y-4 pb-4 text-center">
        <h1 className="pt-8 font-title text-4xl text-red-500">
          Avian
        </h1>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
        <Animal name="Bleeding heart pigeon" type="Gallicolumba Luzonica" img={imgAvian1} color="red" />
        <Animal name="Blue peafowl" type="Pavo Cristatus" img={imgAvian2} color="red" />
        <Animal name="Brahminy kite" type="Haliastus Indus" img={imgAvian3} color="red" />
        <Animal name="Doubled wattled cassowary" type="Casuarius Casuarius" img={imgAvian4} color="red" />
        <Animal name="Egret" type="Egretta Alba" img={imgAvian5} color="red" />
        <Animal name="Eurasia collared dove" type="Streptopelia Decaocto" img={imgAvian6} color="red" />
        <Animal name="Multi coloured stork" type="Ibis Leucophalus" img={imgAvian7} color="red" />
        <Animal name="Palawan pheasant" type="Poyplectron Emphenum" img={imgAvian8} color="red" />
        <Animal name="Philippine serpent eagle" type="Spilornis Holospilus" img={imgAvian9} color="red" />
        <Animal name="Philippine swamphen" type="Porphyrio Porphyria" img={imgAvian10} color="red" />
        <Animal name="Severe macaw" type="Ara Severa x Ara Auricollis" img={imgAvian11} color="red" />
        <Animal name="Sulfur crested cockatoo" type="Cacatua Galerita" img={imgAvian12} color="red" />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
        <Animal name="Black Crowned Night Heron" type="Nycticorax Nycticorax" img={null} color="red" />
        <Animal name="Catalina Macaw" type="Ara Macao x Ara Ararauna" img={null} color="red" />
        <Animal name="Cockatiel" type="Nymphicus Hollandicus" img={null} color="red" />
        <Animal name="Crested Mynah" type="Acridotheres Cristatellus" img={null} color="red" />
        <Animal name="Eagle Owl" type="Bubo Philippensis" img={null} color="red" />
        <Animal name="Giant Scops Owl" type="Mimizuki Gumeyi" img={null} color="red" />
        <Animal name="Green-winged Macaw" type="Ara Chloropterus" img={null} color="red" />
        <Animal name="Moluccan Cockatoo" type="Cacatua Moluccensis" img={null} color="red" />
        <Animal name="Ostrich" type="Struthio Camelus" img={null} color="red" />
        <Animal name="Rufous Night Heron" type="Nycticorax Caledonicus" img={null} color="red" />
      </div>

      <div className="text-white space-y-4 pb-4 text-center">
        <h1 className="pt-8 font-title text-4xl text-purple-500">
          Reptiles
        </h1>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
        <Animal name="African ball python" type="Python Regius" img={imgReptile1} color="purple" />
        <Animal name="Burmese python" type="Python Mulorus Bivitatus" img={imgReptile2} color="purple" />
        <Animal name="Elongata tortoise" type="Geochelone Elongate" img={imgReptile3} color="purple" />
        <Animal name="Monitor lizard" type="Varannus Marmoratus" img={imgReptile4} color="purple" />
        <Animal name="Philippine crocodile" type="Crocodylus Mindorensis" img={imgReptile5} color="purple" />
        <Animal name="Philippine Spitting cobra" type="Naja Philippinensis" img={imgReptile6} color="purple" />
        <Animal name="Pig nosed turtle" type="Carettochelys Insculpta" img={imgReptile7} color="purple" />
        <Animal name="Rat snake" type="Elaphe Spp" img={imgReptile8} color="purple" />
        <Animal name="Red iguana" type="Iguana Iguana" img={imgReptile9} color="purple" />
        <Animal name="Reticulated python" type="Python Reticulatus" img={imgReptile10} color="purple" />
        <Animal name="Sail fin lizard" type="Hydrosaurus Pustulatus" img={imgReptile11} color="purple" />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
        <Animal name="Box Turtle" type="Cuora Amboinensis" img={null} color="purple" />
        <Animal name="Chinese Golden Thread Turtle" type="Mauremys Sinensis" img={null} color="purple" />
        <Animal name="Estuarine Crocodile" type="Crocodylus Porosus" img={null} color="purple" />
        <Animal name="Red-Eared Slider Turtle" type="Trachemys Seripta Elegans" img={null} color="purple" />
        <Animal name="Soft-Shelled Turtle" type="Trionix Spp" img={null} color="purple" />
      </div>

      <div className="text-white space-y-4 pb-4 text-center">
        <h1 className="pt-8 font-title text-4xl text-blue-500">
          Aquatic
        </h1>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
        <Animal name="Alligator Gar" type="Atractosteus spatula" img={null} color="blue" />
        <Animal name="Giant Pangasius Catfish" type="Pangasius sanitwongsei" img={null} color="blue" />
        <Animal name="Gold Fish" type="Carassius auratus" img={null} color="blue" />
        <Animal name="Japanese Carp" type="Cyprinrs Spp" img={null} color="blue" />
        <Animal name="Oscar" type="Astronotus ocellatus" img={null} color="blue" />
        <Animal name="Red Pacu" type="Lepisosteus osseus" img={null} color="blue" />
      </div>
    </div>
  )
}

export default Animals
