import React from 'react'

function ZooReminder() {
  return (
    <div className="space-y-1">
      <div className="font-semibold">
        Reminder:
      </div>
      <div className="font-light">
        {/* The visitors are required to present a valid ID upon entering the zoo. Thank you for your understanding. */}
        <p className="py-2">
          Discounted Visitors are required to present their valid IDs upon entering Manila Zoo:
          <ul className="py-2 mx-2">
            <li>Manila Residents: Valid IDs with Manila address</li>
            <li>Non-Manila Residents: Valid IDs</li>
          </ul>
          <span className="">
            Thank you for your understanding and cooperation.
          </span>
        </p>
      </div>

      <div className="font-semibold italic">
      (Paalala:)
      </div>
      <div className="font-light italic">
        {/* Ang mga bisita ay kinakailangan mag pakita ng valid ID bago pumasok ng zoo. Salamat sa pag unawa.) */}
        Ang mga may Diskwentong Bisita ay kinakailangang magpakita ng kanilang valid IDs bago pumasok ng Manila Zoo. Salamat sa inyong pang-unawa at pakikiisa.
      </div>
    </div>
  )
}

export default ZooReminder