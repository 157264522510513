import React from 'react'
import PropTypes from 'prop-types';

import img1 from 'manila-zoo/assets/plants/1-aglaonema-silver-king.jpg';
import img2 from 'manila-zoo/assets/plants/2-aglaonema-dona-carmen.jpg';
import img3 from 'manila-zoo/assets/plants/3-banka-bangkaan-oyster-plant.jpg';
import img4 from 'manila-zoo/assets/plants/4-basket-plant.jpg';
import img5 from 'manila-zoo/assets/plants/5-boungainvillea.jpg';
import img6 from 'manila-zoo/assets/plants/6-blue-plumbago.jpg';
import img7 from 'manila-zoo/assets/plants/7-bulb-plant-rain-lily.jpg';
import img8 from 'manila-zoo/assets/plants/8-spider-lily.jpg';
import img9 from 'manila-zoo/assets/plants/9-calathea.jpg';
import img10 from 'manila-zoo/assets/plants/10-cophea.jpg';
import img11 from 'manila-zoo/assets/plants/11-copper-leaf.jpg';
import img12 from 'manila-zoo/assets/plants/12-crossandra.jpg';
import img13 from 'manila-zoo/assets/plants/13-golden-duranta.jpg';
import img14 from 'manila-zoo/assets/plants/14-chinese-duranta.jpg';
import img15 from 'manila-zoo/assets/plants/15-emerald-gaiety.jpg';
import img16 from 'manila-zoo/assets/plants/16-episcia.jpg';
import img17 from 'manila-zoo/assets/plants/17-forever-rich.jpg';
import img18 from 'manila-zoo/assets/plants/18-fortune-plant.jpg';
import img19 from 'manila-zoo/assets/plants/19-gumamela.jpg';
import img20 from 'manila-zoo/assets/plants/20-iresine.jpg';
import img21 from 'manila-zoo/assets/plants/21-japanese-bamboo.jpg';
import img22 from 'manila-zoo/assets/plants/22-love-in-the-mist.jpg';
import img23 from 'manila-zoo/assets/plants/23-luhang-dalaga.jpg';
import img24 from 'manila-zoo/assets/plants/24-lucky-bamboo.jpg';
import img25 from 'manila-zoo/assets/plants/25-mandarin.jpg';
import img26 from 'manila-zoo/assets/plants/26-mayana.jpg';
import img27 from 'manila-zoo/assets/plants/27-mexican-petunia.jpg';
import img28 from 'manila-zoo/assets/plants/28-white-moa.jpg';

const Plant = ({ name, img }) => {
  return (
    <div className="relative h-96 bg-gray-100 overflow-hidden rounded-2xl flex flex-col">
      <div className="relative z-10 text-white p-5 mt-auto bg-gradient-to-t from-green-600 to-[#ffffff00]">
        {/* <div className="text-3xl font-title">Monitor Lizard</div> */}
        <p className="text-base font-bold leading-4 uppercase">{name || 'Unknown Name'}</p>
      </div>
      <div className="absolute inset-0 z-0">
        <img
          src={img}
          alt=""
          className="object-cover w-full h-full"
        />
      </div>
    </div>
  )
}

Plant.propTypes = {
  img: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

function Plants() {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
      <Plant name="aglaonema silver king" img={img1} />
      <Plant name="aglaonema dona carmen" img={img2} />
      <Plant name="banka bangkaan oyster plant" img={img3} />
      <Plant name="basket plant" img={img4} />
      <Plant name="boungainvillea" img={img5} />
      <Plant name="blue plumbago" img={img6} />
      <Plant name="bulb plant rain lily" img={img7} />
      <Plant name="spider lily" img={img8} />
      <Plant name="calathea" img={img9} />
      <Plant name="cophea" img={img10} />
      <Plant name="copper leaf" img={img11} />
      <Plant name="crossandra" img={img12} />
      <Plant name="golden duranta" img={img13} />
      <Plant name="chinese duranta" img={img14} />
      <Plant name="emerald gaiety" img={img15} />
      <Plant name="episcia" img={img16} />
      <Plant name="forever rich" img={img17} />
      <Plant name="fortune plant" img={img18} />
      <Plant name="gumamela" img={img19} />
      <Plant name="iresine" img={img20} />
      <Plant name="japanese bamboo" img={img21} />
      <Plant name="love in the mist" img={img22} />
      <Plant name="luhang dalaga" img={img23} />
      <Plant name="lucky bamboo" img={img24} />
      <Plant name="mandarin" img={img25} />
      <Plant name="mayana" img={img26} />
      <Plant name="mexican petunia" img={img27} />
      <Plant name="white moa" img={img28} />
    </div>
  )
}

export default Plants